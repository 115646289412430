export default class Gruppe {
    constructor(id, name, kurz, anz_teams, rueckrunde, teams = [], tabelle = []){
        this.id = id
        this.name = name
        this.kurz = kurz
        this.anz_teams = anz_teams
        this.rueckrunde = rueckrunde
        this.teams = teams
        this.tabelle = tabelle
    }

    toFirestore(){
        return {
            id: this.id,
            name: this.name,
            kurz: this.kurz,
            anz_teams: this.anz_teams,
            rueckrunde: this.rueckrunde,
            teams: this.teams
        }
    }
}