import { collection, doc, DocumentReference, getDocs, getFirestore, onSnapshot, query, where } from 'firebase/firestore';
import md5 from 'md5';
import rundeConverter from '../rundeConverter';
import teamConverter from '../spiel/teamConverter';
import { Ansprechpartner } from './ansprechpartner';
import { Anzeigetafel_Settings } from './anzeigetafel_settings';
import { Ausrichter } from './ausrichter';
import { Cafeteria } from './cafeteria';
import { Spieler } from './spieler';
import sponsorConverter from './sponsorConverter';
import { Turnieranmeldung_Settings } from './turnieranmeldung_settings';
import { Turniermodus_Settings } from './turniermodus_settings';
import { Turnierwebsite_Settings } from './turnierwebsite_settings';
import { Veranstaltungsort } from './veranstaltungsort';
import { Zeitraum_Tag } from './zeitraum_tag';

class Turnier {
    constructor(
        id,
        name,
        alias,
        altersklasse,
        ansprechpartner = [],
        art,
        turniermodus,
        status,
        anmeldung,
        veranstaltungsorte,
        zeitraum,
        website,
        user,
        ausrichter,
        ref,
        anzeigetafel_settings,
        cafeteria
    ) {
        this.id = id || md5(Date.now())
        this.name = name || ''
        this.alias = alias || ''
        this.altersklasse = altersklasse || ''
        this.ansprechpartner = Array.isArray(ansprechpartner) ? 
            ansprechpartner.map(item => {
                if(item instanceof Ansprechpartner) {
                    return item
                } 
                else if(item instanceof Object){
                    return new Ansprechpartner(item?.id, item?.name, item?.telefon, item?.mobil, item?.email, item?.whatsapp, item?.telefon_public, item?.mobil_public, item?.email_public, item?.whatsapp_public)
                }
            }) : []
        this.art = art || 'Hallenturnier'
        this.turniermodus = turniermodus instanceof Turniermodus_Settings ? turniermodus : new Turniermodus_Settings(turniermodus) 
        this.status = status || 'Planung'
        this.anmeldung = anmeldung instanceof Turnieranmeldung_Settings ? anmeldung : new Turnieranmeldung_Settings(anmeldung?.freigabe, anmeldung?.gebuehr, anmeldung?.manuell, anmeldung?.required, anmeldung?.zeitraum)
        this.veranstaltungsorte = Array.isArray(veranstaltungsorte) ? veranstaltungsorte.map(item => {
            if(item instanceof Veranstaltungsort){
                return item
            }
            else if(item instanceof Object){
                return new Veranstaltungsort(item.name, item.strasse, item.nr, item.plz, item.ort)
            }
        }) : [ new Veranstaltungsort() ]
        this.zeitraum = Array.isArray(zeitraum) ? zeitraum.map(item => {
            if(item instanceof Zeitraum_Tag){
                return item
            }
            else if(item instanceof Object){
                return new Zeitraum_Tag(item.id, item.datum, item.endzeit, item.ref, item.startzeit)
            }
        }) : [
            new Zeitraum_Tag()
        ]
        this.website = website instanceof Turnierwebsite_Settings ? website : new Turnierwebsite_Settings(website?.beschreibung, website?.modus, website?.theme, website?.sites, website?.logo, website?.live_spielzeit)
        this.user = user || []
        this.ref = ref instanceof DocumentReference ? ref : doc(getFirestore(), 'Turniere/'+this.id)

        this.ausrichter = ausrichter instanceof Ausrichter ? ausrichter : new Ausrichter(ausrichter?.id, ausrichter?.name, ausrichter?.logo)

        //Teams
        this.teamSnapshot = () => {}
        this.teams = []
        this.getTeams()

        //Runden
        this.rundenSnapshot = () => {}
        this.runden = []
        this.spiele = []
        this.torjaegerliste = []
        this.getRunden()
        this.create_torjaegerliste()

        //Sponsoren
        this.sponsoren = []
        this.getSponsoren()

        this.anzeigetafel_settings = new Anzeigetafel_Settings(anzeigetafel_settings?.background_image, anzeigetafel_settings?.css)

        this.cafeteria = new Cafeteria(cafeteria?.artikel)
    }

    destructor(){
        this.rundenSnapshot()
        this.teamSnapshot()
    }

    async getRunden(){
        const q = query(collection(getFirestore(), 'Turniere/'+this.id+'/Runden'), where('name', '!=', 'abcdefg12345678')).withConverter(rundeConverter)
        this.rundenSnapshot = onSnapshot(q, snap => {
            this.runden = []
            this.spiele = []
            snap.forEach(item => {
                const runde = item.data()
                runde.spiele.sort((a, b) => a.start.get_timestamp() - b.start.get_timestamp())
                this.runden.push(runde)
                this.spiele = this.spiele.concat(runde.spiele)
                this.spiele.sort((a, b) => a.start.get_timestamp() - b.start.get_timestamp())
                this.runden.sort((a,b) => a.beginn.get_timestamp() - b.beginn.get_timestamp())
                this.create_torjaegerliste()
            })
            
        })
    }

    get_aktuelles_spiel() {
        return this.spiele.find(item => item.status == 'gestartet' || item.status == 'geplant')
    }

    async getTeams(){
        const q = query(collection(getFirestore(), 'Turniere/'+this.id+'/Teams'), where('name', '!=', 'Test1234567890?')).withConverter(teamConverter)
        this.teamSnapshot = onSnapshot(q, snap => {
            this.teams = []
            snap.forEach(item => {
                this.teams.push(item.data())
                this.teams.sort((a, b) => a.name > b.name ? 1 : -1)
            })
        })
    }
    
    async getSponsoren(){
        const q = collection(getFirestore(), 'Turniere/'+this.id+'/Sponsoren').withConverter(sponsorConverter)
        const sponsoren = await getDocs(q)
        this.sponsoren = []
        sponsoren.forEach(item => {
            this.sponsoren.push(item.data())
        })
    }

    async create_torjaegerliste(){
        this.torjaegerliste = []
        this.teams.forEach(team => {
            if(team.kader.length > 0){
                team.kader.forEach(spieler => {
                    const spiele_mit_tor = this.spiele.filter(item => item.torschuetzen.find(ts => ts.team == team.id && ts.spieler == spieler.id))
                    spieler.erzielte_tore = 0
                    spiele_mit_tor.forEach(spiel => {
                        let tore = spiel.torschuetzen.filter(ts => ts.team == team.id && ts.spieler == spieler.id)
                        spieler.erzielte_tore += tore.length
                    })
                    this.torjaegerliste.push({team: team, spieler: spieler, tore: spieler.erzielte_tore})
                    this.torjaegerliste.sort((a,b) => b.tore - a.tore)
                })
            }
            else {
                for(var i=1; i<100; i++){
                    const spiele_mit_tor = this.spiele.filter(item => item.torschuetzen.find(ts => ts.team == team.id && ts.spieler == md5(i)))
                    let erzielte_tore = 0
                    spiele_mit_tor.forEach(spiel => {
                        let tore = spiel.torschuetzen.filter(ts => ts.team == team.id && ts.spieler == md5(i))
                        erzielte_tore += tore.length
                    })
                    if(erzielte_tore > 0){
                        this.torjaegerliste.push({team: team, spieler: new Spieler(md5(i), 'Spieler Nr.', i, i), tore: erzielte_tore})
                        this.torjaegerliste.sort((a,b) => b.tore - a.tore)
                    }
                }
            }
        })
    }

    toFirestore(){
        return {
            alias: this.alias,
            altersklasse: this.altersklasse,
            ansprechpartner: this.ansprechpartner.map(item => { return item.toFirestore() }),
            name: this.name,
            art: this.art,
            turniermodus: this.turniermodus.toFirestore(),
            status: this.status,
            anmeldung: this.anmeldung.toFirestore(),
            veranstaltungsorte: this.veranstaltungsorte.map(item => { return item.toFirestore() }),
            zeitraum: this.zeitraum.map(item => { return item.toFirestore() }),
            website: this.website.toFirestore(),
            user: this.user,
            ausrichter: this.ausrichter.toFirestore(),
            anzeigetafel_settings: this.anzeigetafel_settings.toFirestore(),
            cafeteria: this.cafeteria.toFirestore()
        }
    }
}

export default Turnier