import md5 from "md5"

export class Ausrichter {
    constructor(
        id,
        name,
        logo
    ) {
        this.id = id || md5(Date.now())
        this.name = name || ''
        this.logo = logo || ''
    }

    toFirestore() {
        return {
            id: this.id,
            name: this.name,
            logo: this.logo
        }
    }
}