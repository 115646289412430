export class Turnieranmeldung_Settings {
    constructor(
        freigabe = false, 
        gebuehr = '', 
        manuell = false,  
        required = { 
            benutzer_felder: [],
            team: true,
            trainer: true,
            verein: true
        },
        zeitraum = {
            beginn: '', //Date
            ende: '' //Date
        }
    ){
        this.freigabe = freigabe
        this.gebuehr = gebuehr
        this.manuell = manuell
        this.required = required
        this.zeitraum = zeitraum
    }

    toFirestore(){
        return {
            freigabe: this.freigabe,
            gebuehr: this.gebuehr,
            manuell: this.manuell,
            required: this.required,
            zeitraum: this.zeitraum
        }
    }
}