import FuninoTeam from "./funinoTeam";

const funinoTeamConverter = {
    toFirestore: (team) => {
        return team.toFirestore()
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new FuninoTeam(
            snapshot.id,
            data.name,
            data.logo,
            data.logo_round,
            data.logo_background,
            snapshot.ref,
            data.kader,
            data.trikots,
            data.mannschaftsart,
            data.spielstaerke,
            data.jahrgang,
            data.anzahl_spiele,
            data.heimspiel_wunsch,
            data.spielfrei,
            data.trainer
        )
    }
}

export default funinoTeamConverter