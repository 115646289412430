import { Spieltag } from "./spieltag";

const spieltagConverter = {
    toFirestore: (runde) => {
        return runde.toFirestore()
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Spieltag(
            snapshot.id,
            data.datum,
            data.startzeit,
            data.name,
            data.spiele
        )
    }
}

export default spieltagConverter