import { HeaderImage, TurnierLogo } from "./website/image"
import { Theme } from "./website/theme"

export class Turnierwebsite_Settings {
    constructor(
        beschreibung,
        modus,
        theme,
        sites,
        logo,
        live_spielzeit
    ) {
        this.beschreibung = beschreibung || ''
        this.modus = modus || 'Spielplan'
        this.theme = theme instanceof Theme ? theme : new Theme(theme?.primary, theme?.secondary, theme?.success, theme?.warning, theme?.danger, theme?.background, theme?.header, theme?.darkmode)
        this.sites = Array.isArray(sites) || ['Spielplan', 'Statistiken', 'Regeln']
        this.logo = logo instanceof Object ? new TurnierLogo(logo.id, logo.name, logo.type, logo.path) : new TurnierLogo()
        this.live_spielzeit = live_spielzeit || false
    }

    toFirestore(){
        return {
            beschreibung: this.beschreibung,
            modus: this.modus,
            theme: this.theme.toFirestore(),
            sites: this.sites,
            logo: this.logo.toFirestore(),
            live_spielzeit: this.live_spielzeit
        }
    }
}