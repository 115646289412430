import { HeaderImage } from "./website/image";

export class Anzeigetafel_Settings {
    constructor(
        background_image = new HeaderImage(),
        css = ''
    ) {
        this.background_image = background_image
        this.css = css
    }

    toFirestore(){
        return {
            background_image: '',
            css: this.css
        }
    }
}