import md5 from "md5";
import FuninoSpiel from "../../spiel/liga/funinoSpiel";
import Zeit from "../../zeit";

export class Spieltag {
    constructor(id, datum, startzeit, name, spiele){
        this.id = id || md5(Date.now())
        this.datum = datum
        this.startzeit = startzeit
        this.start = new Zeit(datum, startzeit)
        this.name = name
        this.spiele = Array.isArray(spiele) ? spiele.map(spiel => {
            return new FuninoSpiel(spiel.id, spiel.start, spiel.ende, spiel.spieltag, spiel.teams, spiel.heimteam)
        }) : []
    }

    toFirestore(){
        return {
            id: this.id,
            datum: this.datum || '',
            startzeit: this.startzeit || '',
            name: this.name || '',
            spiele: this.spiele.map(spiel => {
                return spiel.toFirestore()
            })
        }
    }
}