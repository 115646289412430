export default class Spielfeld {
    constructor(id, name, kurz){
        this.id = id
        this.name = name
        this.kurz = kurz
    }

    toFirestore(){
        return {
            id: this.id,
            name: this.name,
            kurz: this.kurz
        }
    }
}