import { doc, DocumentReference, getFirestore } from "firebase/firestore"
import { Spieler } from "../../turnier/spieler"
import { Tormusik } from "../tormusik"
import { Trainer } from "./trainer"

export default class FuninoTeam {
    constructor(id, name, logo, logo_round, logo_background, ref, kader, trikots, mannschaftsart, spielstaerke, jahrgang, anzahl_spiele, heimspiel_wunsch = [], spielfrei = [], trainer = []){
        this.id = id
        this.name = name
        this.logo = logo
        this.logo_round = logo_round || true
        this.logo_background = logo_background || 'transparent'

        this.ref = ref instanceof DocumentReference ? ref : ''
        this.kader = Array.isArray(kader) ? kader.map(item => {
            return new Spieler(item.id, item.vorname, item.nachname, item.trikotnummer)
        }) : []
        this.kader.sort((a, b) => a.trikotnummer - b.trikotnummer)
        this.trikots = {
            heimfarbe: trikots?.heimfarbe || '',
            ausweichfarbe: trikots?.ausweichfarbe || ''
        }
        this.mannschaftsart = mannschaftsart || 'G1'
        this.spielstaerke = spielstaerke || 'Normal'
        this.jahrgang = jahrgang || ''
        this.anzahl_spiele = parseInt(anzahl_spiele) || 1
        this.heimspiel_wunsch = Array.isArray(heimspiel_wunsch) ? heimspiel_wunsch : []
        this.spielfrei = Array.isArray(spielfrei) ? spielfrei : []
        this.trainer = Array.isArray(trainer) ? trainer.map(coach => {
            return new Trainer(coach.id, coach.vorname, coach.nachname, coach.mobil, coach.whatsapp, coach.email)
        }) : []
    }

    toFirestore(){
        return {
            id: this.id,
            name: this.name,
            logo: this.logo,
            logo_round: this.logo_round || '',
            logo_round_background: this.logo_round_background || '',
            kader: this.kader.map(item => {
                return item.toFirestore()
            }),
            trikots: this.trikots,
            spielstaerke: this.spielstaerke,
            jahrgang: this.jahrgang,
            mannschaftsart: this.mannschaftsart,
            anzahl_spiele: this.anzahl_spiele,
            heimspiel_wunsch: this.heimspiel_wunsch,
            spielfrei: this.spielfrei,
            trainer: this.trainer.map(coach => {
                if(coach instanceof Trainer){
                    return coach.toFirestore()
                }
            })
        }
    }
}