import { Sponsor } from "./sponsor";

const sponsorConverter = {
    toFirestore: (sponsor) => {
        return sponsor.toFirestore()
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Sponsor(snapshot.id, data.name, data.logo, data.website)
    }
}

export default sponsorConverter