import EventEmitter from 'events'
import md5 from 'md5'

export class ZweiMinutenStrafe extends EventEmitter {
    constructor(
        id = md5(Date.now()),
        status = 'pausiert',
        timestamp = Date.now(),
        verbleibend = {
            minuten: 2,
            sekunden: 0
        }
    ) {
        super();
        this.id = id,
        this.status = status
        this.timestamp = timestamp,
        this.verbleibend = verbleibend
        this.timer = {
            minuten: verbleibend.minuten,
            sekunden: verbleibend.sekunden
        }
        this.countdown_tick()
    }

    zeit_starten(){
        this.status = 'gestartet'
        this.timestamp = Date.now()
        this.countdown_tick()
    }

    zeit_stoppen(){
        this.status = 'pausiert'
        this.verbleibend.minuten = parseInt(this.timer.minuten)
        this.verbleibend.sekunden = parseInt(this.timer.sekunden)
    }

    countdown_tick(){
        if(this.status == 'gestartet') {
            const now = Date.now()
            const zeitstrafe_seconds = (this.verbleibend.minuten * 60) + this.verbleibend.sekunden 
            const seconds = parseInt((now - this.timestamp) / 1000)

            this.timer.sekunden = parseInt(zeitstrafe_seconds - seconds) % 60
            this.timer.minuten = parseInt((zeitstrafe_seconds - seconds) / 60)

            if(this.timer.sekunden <= 0){
                this.timer.sekunden = 0
            }
            if(this.timer.minuten <= 0){
                this.timer.minuten = 0
            }

            if(this.timer.minuten == 0 && this.timer.sekunden == 0){
                this.emit('zeitstrafe_beendet')
                this.zeit_stoppen()
            }

            setTimeout(() => {
                this.countdown_tick()
            }, 1000)
        }
    }

    toFirestore(){
        return {
            id: this.id,
            timestamp: this.timestamp,
            verbleibend: {
                minuten: this.verbleibend.minuten,
                sekunden: this.verbleibend.sekunden
            }
        }
    }
}