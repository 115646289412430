import { Color } from "./color";
import { HeaderImage } from "./image";

export class Theme {
    constructor(
        primary = {
            alpha: 1,
            hex: "#3C8EC6",
            hexa: "#3C8EC6FF",
            hsla: {
                a: 1,
                h: 204.34782608695653,
                l: 0.5058823529411764,
                s: 0.5476190476190477
            },
            hsva: {
                a: 1,
                h: 204.34782608695653,
                s: 0.696969696969697,
                v: 0.7764705882352941
            },
            hue: 204.34782608695653,
            rgba: {
                a: 1,
                b: 198,
                g: 142,
                r: 60
            }
        },
        secondary = {
            alpha: 1,
            hex: "#0B233D",
            hexa: "#0B233DFF",
            hsla: {
                a: 1,
                h: 211.2,
                l: 0.1411764705882353,
                s: 0.6944444444444445
            },
            hsva: {
                a: 1,
                h: 211.2,
                s: 0.819672131147541,
                v: 0.23921568627450981
            },
            hue: 211.2,
            rgba: {
                a: 1,
                b: 61,
                g: 35,
                r: 11
            }
        },
        success = {
            alpha: 1,
            hex: "#2BD66B",
            hexa: "#2BD66BFF",
            hsla: {
                a: 1,
                h: 142.4561403508772,
                l: 0.503921568627451,
                s: 0.6758893280632412
            },
            hsva: {
                a: 1,
                h: 142.4561403508772,
                s: 0.7990654205607477,
                v: 0.8392156862745098
            },
            hue: 142.4561403508772,
            rgba: {
                a: 1,
                b: 107,
                g: 214,
                r: 43
            }
        },
        warning = {
            alpha: 1,
            hex: "#D0ED3B",
            hexa: "#D0ED3BFF",
            hsla: {
                a: 1,
                h: 69.7752808988764,
                l: 0.5803921568627451,
                s: 0.8317757009345795
            },
            hsva: {
                a: 1,
                h: 69.7752808988764,
                s: 0.751054852320675,
                v: 0.9294117647058824
            },
            hue: 69.7752808988764,
            rgba: {
                a: 1,
                b: 59,
                g: 237,
                r: 208
            }
        },
        danger = {
            alpha: 1,
            hex: "#F75331",
            hexa: "#F75331FF",
            hsla: {
                a: 1,
                h: 10.303030303030303,
                l: 0.580392156862745,
                s: 0.9252336448598131
            },
            hsva: {
                a: 1,
                h: 10.303030303030303,
                s: 0.8016194331983806,
                v: 0.9686274509803922
            },
            hue: 10.303030303030303,
            rgba: {
                a: 1,
                b: 49,
                g: 83,
                r: 247
            }
        },
        background = {
            alpha: 1,
            hex: "#130A19",
            hexa: "#130A19FF",
            hsla: {
                a: 1,
                h: 276,
                l: 0.06862745098039216,
                s: 0.42857142857142844
            },
            hsva: {
                a: 1,
                h: 276,
                s: 0.6,
                v: 0.09803921568627451
            },
            hue: 276,
            rgba: {
                a: 1,
                b: 25,
                g: 10,
                r: 19
            }
        },
        header = {
            color: '',
            overlay: {
                alpha: 0.8,
                hex: "#130A19",
                hexa: "#130A19CC",
                hsla: {
                    a: 0.8,
                    h: 276,
                    l: 0.06862745098039216,
                    s: 0.42857142857142844
                },
                hsva: {
                    a: 0.8,
                    h: 276,
                    s: 0.6,
                    v: 0.09803921568627451
                },
                hue: 276,
                rgba: {
                    a: 0.8,
                    b: 25,
                    g: 10,
                    r: 19
                }
            },
            image: new HeaderImage('', 'Default', 'jpg', '/background/backgroundo04.jpg', false)
        },
        darkmode = true
    ) {
        this.primary = primary instanceof Color ? primary : new Color(primary?.alpha, primary?.hex, primary?.hexa, primary?.hsla, primary?.hsva, primary?.hue, primary?.rgba)
        this.background = background instanceof Color ? background : new Color(background?.alpha, background?.hex, background?.hexa, background?.hsla, background?.hsva, background?.hue, background?.rgba)
        this.danger = danger instanceof Color ? danger : new Color(danger?.alpha, danger?.hex, danger?.hexa, danger?.hsla, danger?.hsva, danger?.hue, danger?.rgba)
        this.warning = warning instanceof Color ? warning : new Color(warning?.alpha, warning?.hex, warning?.hexa, warning?.hsla, warning?.hsva, warning?.hue, warning?.rgba)
        this.success = success instanceof Color ? success : new Color(success?.alpha, success?.hex, success?.hexa, success?.hsla, success?.hsva, success?.hue, success?.rgba)
        this.secondary = secondary instanceof Color ? secondary : new Color(secondary?.alpha, secondary?.hex, secondary?.hexa, secondary?.hsla, secondary?.hsva, secondary?.hue, secondary?.rgba)
        this.header = {
            color: header?.color || '',
            overlay: header?.overlay instanceof Color ? header.overlay : new Color(header?.overlay?.alpha, header?.overlay?.hex, header?.overlay?.hexa, header?.overlay?.hsla, header?.overlay?.hsva, header?.overlay?.hue, header?.overlay?.rgba),
            image: header.image instanceof Object ? new HeaderImage(header?.image?.id, header?.image?.name, header?.image?.type, header?.image?.path, header?.image?.customImage) : new HeaderImage('', 'Default', 'jpg', '/background/backgroundo04.jpg', false)
        }
        this.darkmode = darkmode
    }

    isDark(){
        return this.background.isDark()
    }

    toFirestore(){
        return {
            primary: this.primary.toFirestore(),
            secondary: this.secondary.toFirestore(),
            success: this.success.toFirestore(),
            warning: this.warning.toFirestore(),
            danger: this.danger.toFirestore(),
            background: this.background.toFirestore(),
            header: {
                color: this.header?.color instanceof Color ? this.header?.color?.toFirestore() : '',
                overlay: this.header.overlay.toFirestore(),
                image: this.header?.image?.toFirestore()
            },
            darkmode: this.darkmode
        }
    }
}