import md5 from "md5"

export class Sponsor {
    constructor(id, name, logo, website){
        this.id = id || md5(Date.now())
        this.name = name || ''
        this.logo = logo || ''
        this.website = website || ''
    }

    toFirestore(){
        return {
            id: this.id,
            name: this.name || '',
            logo: this.logo || '',
            website: this.website || ''
        }
    }
}