export function getTemplate(template) {
  var templateobj = ''
  switch (template) {
    case 'flutlicht01':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fflutlicht%2Fflutlicht01.jpg?alt=media&token=f0cfadb0-b804-49ed-be58-47bdb88dff0c',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'transparent',
          mobile_nav: '#000',
          sponsor: '#263238',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#214f7b',
          },
          section01: {
            color: '#000000',
            chips: '#214f7b',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#082742',
            shadow: '',
          },
          footer: '#000',
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'flutlicht02':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fflutlicht%2Fflutlicht02.jpg?alt=media&token=9464daf7-0154-49f8-9ad6-81189ce9c7e5',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'transparent',
          mobile_nav: '#000',
          sponsor: '#263238',
          start: {
            title: '#fff',
            text: '#fff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#7e9349',
          },
          section01: {
            color: '#0f160a',
            chips: '#7e9349',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#fff',
            text: '#0f160a',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          footer: '#000',
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'flutlicht03':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fflutlicht%2Fflutlicht03.jpg?alt=media&token=9892d3d5-93df-469f-bfbe-87d3ce85f4a0',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'transparent',
          mobile_nav: '#154f79',
          sponsor: '#021d31',
          start: {
            title: '#fff',
            text: '#fff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#286f9b',
          },
          section01: {
            color: '#021d31',
            chips: '#286f9b',
            text: '#fff',
            title: '#286f9b',
            shadow: '',
          },
          section02: {
            color: '#aed7f3',
            text: '#021d31',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          footer: '#000',
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'flutlicht04':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fflutlicht%2Fflutlicht04.jpg?alt=media&token=63c7e570-3f1b-4041-957a-60f4199e927f',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'transparent',
          mobile_nav: '#000',
          sponsor: '#222',
          start: {
            title: '#fff',
            text: '#fff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#bbb',
          },
          section01: {
            color: '#ddd',
            chips: '#111',
            text: '#000',
            title: '#111',
            shadow: '',
          },
          section02: {
            color: '#888',
            text: '#111',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          footer: '#000',
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'flutlicht05':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fflutlicht%2Fflutlicht05.jpg?alt=media&token=f83f2aa7-dd84-42ef-a084-4bfdcb6add83',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'transparent',
          mobile_nav: '#154f79',
          sponsor: '#021d31',
          start: {
            title: '#fff',
            text: '#fff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#286f9b',
          },
          section01: {
            color: '#021d31',
            chips: '#286f9b',
            text: '#fff',
            title: '#286f9b',
            shadow: '',
          },
          section02: {
            color: '#aed7f3',
            text: '#021d31',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          footer: '#000',
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'flutlicht06':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fflutlicht%2Fflutlicht06.jpg?alt=media&token=ce1f12de-0765-4eac-ab80-875914b9bb1e',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'transparent',
          mobile_nav: '#000',
          sponsor: '#263238',
          start: {
            title: '#fff',
            text: '#fff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#7e9349',
          },
          section01: {
            color: '#0f160a',
            chips: '#7e9349',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#fff',
            text: '#0f160a',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          footer: '#000',
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'flutlicht07':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fflutlicht%2Fflutlicht07.jpg?alt=media&token=f65e65a8-aaef-4fad-ac96-e62ee1e1e151',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#ECEFF1',
          navigation: 'transparent',
          mobile_nav: '#455A64',
          sponsor: '#455A64',
          footer: '#263238',
          start: {
            title: '#fff',
            text: '#fff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#455A64',
          },
          section01: {
            color: '#020309',
            chips: '#455A64',
            text: '#88a7b9',
            title: '#88a7b9',
            shadow: '',
          },
          section02: {
            color: '#88a7b9',
            text: '#020309',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows: '3px 3px 10px rgba(50, 50, 50, 1)',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'flutlicht08':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fflutlicht%2Fflutlicht08.jpg?alt=media&token=bb76a080-7570-44fe-811b-7e90fac0648e',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#ECEFF1',
          navigation: 'rgba(0,0,0,0.5)',
          mobile_nav: '#15202e',
          sponsor: 'rgba(0,0,0,0.5)',
          footer: '#263238',
          start: {
            title: '#fff',
            text: '#fff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#39485a',
          },
          section01: {
            color: '#080a0a',
            chips: '#39485a',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#fff',
            text: '#080a0a',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'flutlicht09':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fflutlicht%2Fflutlicht09.jpg?alt=media&token=ddea64da-6fab-4509-b5a5-13cbac87a169',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#ECEFF1',
          navigation: 'rgba(0,0,0,0.5)',
          mobile_nav: '#000',
          sponsor: 'rgba(0,0,0,0.5)',
          footer: '#263238',
          start: {
            title: '#fff',
            text: '#fff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#f09485',
          },
          section01: {
            color: '#080a0a',
            chips: '#f09485',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#fff',
            text: '#080a0a',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'flutlicht10':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fflutlicht%2Fflutlicht10.jpg?alt=media&token=122be601-ca62-4167-8eb0-d03360625207',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#ECEFF1',
          navigation: 'rgba(0,0,0,0.5)',
          mobile_nav: 'rgba(0,0,0,0.5)',
          sponsor: 'rgba(0,0,0,0.5)',
          footer: '#263238',
          start: {
            title: '#fff',
            text: '#fff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#1f3d59',
          },
          section01: {
            color: '#131927',
            chips: '#1f3d59',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#dbe8f0',
            text: '#131927',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'flutlicht11':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fflutlicht%2Fflutlicht11.jpg?alt=media&token=fd03488b-e459-4c13-8d8d-585a56a12a7a',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#ECEFF1',
          navigation: 'rgba(0,0,0,0.5)',
          mobile_nav: '#000',
          sponsor: 'rgba(0,0,0,0.5)',
          footer: '#263238',
          start: {
            title: '#fff',
            text: '#fff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#547735',
          },
          section01: {
            color: '#080a0a',
            chips: '#547735',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#fff',
            text: '#080a0a',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break

    case 'neon01':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fneon%2Fneon01.jpg?alt=media&token=f13ec4c3-2847-4600-a106-2888929f11c7',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'transparent',
          mobile_nav: '#000',
          sponsor: '#263238',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#214f7b',
          },
          section01: {
            color: '#000000',
            chips: '#214f7b',
            text: '#fafafa',
            title: '#fafafa',
            shadow:
              'text-shadow: #214f7b 0px 0px 5px, #214f7b 0px 0px 10px, #214f7b 0px 0px 15px, #214f7b 0px 0px 20px, #214f7b 0px 0px 30px, #214f7b 0px 0px 40px, #214f7b 0px 0px 50px, #214f7b 0px 0px 75px;',
          },
          section02: {
            color: '#fafafa',
            text: '#082742',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          footer: '#000',
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'neon02':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fneon%2Fneon02.jpg?alt=media&token=3ccb6b44-2f82-4981-83d3-346a12d5e806',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          mobile_nav: '#000',
          sponsor: '#263238',
          footer: '#000',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#214f7b',
          },
          section01: {
            color: '#000000',
            chips: '#214f7b',
            text: '#fafafa',
            title: '#fafafa',
            shadow:
              'text-shadow: #214f7b 0px 0px 5px, #214f7b 0px 0px 10px, #214f7b 0px 0px 15px, #214f7b 0px 0px 20px, #214f7b 0px 0px 30px, #214f7b 0px 0px 40px, #214f7b 0px 0px 50px, #214f7b 0px 0px 75px;',
          },
          section02: {
            color: '#fafafa',
            text: '#082742',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'neon03':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fneon%2Fneon03.jpg?alt=media&token=d2e83144-9e32-48e5-ae19-c00592fdd47f',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          mobile_nav: '#000',
          sponsor: '#263238',
          footer: '#000',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#214f7b',
          },
          section01: {
            color: '#000000',
            chips: '#214f7b',
            text: '#fafafa',
            title: '#fafafa',
            shadow:
              'text-shadow: #214f7b 0px 0px 5px, #214f7b 0px 0px 10px, #214f7b 0px 0px 15px, #214f7b 0px 0px 20px, #214f7b 0px 0px 30px, #214f7b 0px 0px 40px, #214f7b 0px 0px 50px, #214f7b 0px 0px 75px;',
          },
          section02: {
            color: '#fafafa',
            text: '#082742',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'neon04':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fneon%2Fneon04.jpg?alt=media&token=c266820e-18e9-4ba5-887d-681fc2518f73',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          mobile_nav: '#000',
          sponsor: '#263238',
          footer: '#000',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#d00550',
          },
          section01: {
            color: '#000000',
            chips: '#d00550',
            text: '#fafafa',
            title: '#d00550',
            shadow: '',
          },
          section02: {
            color: '#b86ab2',
            text: '#ede8f1',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'neon05':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fneon%2Fneon05.jpg?alt=media&token=57562a54-0e53-45db-b511-8a0238319453',
          position: 'center center',
          opacity: 0.85
        },
        dark: true,
        light: false,
        colors: {
          background: '#151514',
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          mobile_nav: '#151514',
          sponsor: '#263238',
          footer: '#000',
          primary: '#9B2335',
          secondary: '#DC0B23',
          accent1: '#E00713',
          accent2: '#F0EBCB',
          info: '#7C90A0',
          warning: '#FFF275',
          success: '#60712F',
          green: '#9EA93F',
          start: {
            title: '#ffffff',
            text: '#ffffff',
            countdown_big: '#ffffff',
            countdown_light: '#ffffff',
            chips: '#3c8ec6',
          },
          section01: {
            color: '#151514',
            chips: '#9B2335',
            text: '#ffffff',
            title: '#3c8ec6',
            shadow: '',
          },
          section02: {
            color: '#0b233d',
            text: '#fef6f9',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'neon05a':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fneon%2Fneon05.jpg?alt=media&token=57562a54-0e53-45db-b511-8a0238319453',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          background: '#130a19',
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          mobile_nav: '#130a19',
          sponsor: '#263238',
          footer: '#000',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#3c8ec6',
          },
          section01: {
            color: '#130a19',
            chips: '#3c8ec6',
            text: '#fafafa',
            title: '#3c8ec6',
            shadow: '',
          },
          section02: {
            color: '#0b233d',
            text: '#fef6f9',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'neon06':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fneon%2Fneon06.jpg?alt=media&token=dc04ceba-b0f6-4eed-82a5-c0343e1791e8',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          mobile_nav: '#040508',
          sponsor: '#263238',
          footer: '#000',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#324258',
          },
          section01: {
            color: '#040508',
            chips: '#324258',
            text: '#fcfdfb',
            title: '#fcfdfb',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#082742',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'neon07':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fneon%2Fneon07.jpg?alt=media&token=e51b40db-c14b-4106-92a8-917fc67cb2e0',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          mobile_nav: '#040508',
          sponsor: '#263238',
          footer: '#000',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#324258',
          },
          section01: {
            color: '#040508',
            chips: '#324258',
            text: '#fcfdfb',
            title: '#fcfdfb',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#082742',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'neon08':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fneon%2Fneon08.jpg?alt=media&token=6d8a7fe5-2e11-424f-bdf2-c6071069c876',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#214f7b',
          },
          section01: {
            color: '#000000',
            chips: '#214f7b',
            text: '#fafafa',
            title: '#fafafa',
            shadow:
              'text-shadow: #214f7b 0px 0px 5px, #214f7b 0px 0px 10px, #214f7b 0px 0px 15px, #214f7b 0px 0px 20px, #214f7b 0px 0px 30px, #214f7b 0px 0px 40px, #214f7b 0px 0px 50px, #214f7b 0px 0px 75px;',
          },
          section02: {
            color: '#fafafa',
            text: '#082742',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'neon09':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fneon%2Fneon09.jpg?alt=media&token=35fc1419-6621-41f8-a59b-3360f839cc66',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#214f7b',
          },
          section01: {
            color: '#000000',
            chips: '#214f7b',
            text: '#fafafa',
            title: '#fafafa',
            shadow:
              'text-shadow: #214f7b 0px 0px 5px, #214f7b 0px 0px 10px, #214f7b 0px 0px 15px, #214f7b 0px 0px 20px, #214f7b 0px 0px 30px, #214f7b 0px 0px 40px, #214f7b 0px 0px 50px, #214f7b 0px 0px 75px;',
          },
          section02: {
            color: '#fafafa',
            text: '#082742',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break

    case 'stadium01':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fstadium%2Fstadium01.jpg?alt=media&token=c4a6f3df-6a08-4384-b54b-156e6a782612',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: '#85b4e8',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: '#85b4e8',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#214f7b',
          },
          section01: {
            color: '#000000',
            chips: '#85b4e8',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#082742',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'stadium02':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fstadium%2Fstadium02.jpg?alt=media&token=6f03c32a-2a6d-4a6b-bced-0faee8ef4150',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: 'rgba(0,0,0,1)',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#689F38',
          },
          section01: {
            color: '#000000',
            chips: '#689F38',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#000000',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'stadium03':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fstadium%2Fstadium03.jpg?alt=media&token=28210ce8-1287-4f3c-ba55-33db728daac5',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: 'rgba(0,0,0,1)',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#F57C00',
          },
          section01: {
            color: '#000000',
            chips: '#F57C00',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#000000',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'stadium04':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fstadium%2Fstadium04.jpg?alt=media&token=f811e6dc-f40e-474c-ae33-03aae6251aac',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: 'rgba(0,0,0,1)',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#9CCC65',
          },
          section01: {
            color: '#000000',
            chips: '#9CCC65',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#000000',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'stadium05':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fstadium%2Fstadium05.jpg?alt=media&token=90286dcf-6fc8-4946-952a-2ea6dc48e4b6',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: 'rgba(0,0,0,1)',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#9CCC65',
          },
          section01: {
            color: '#000000',
            chips: '#9CCC65',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#000000',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'stadium06':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fstadium%2Fstadium06.jpg?alt=media&token=a35928ad-c701-43be-a3e5-58a0b77d8370',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: 'rgba(0,0,0,1)',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#558B2F',
          },
          section01: {
            color: '#000000',
            chips: '#558B2F',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#000000',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'stadium07':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fstadium%2Fstadium07.jpg?alt=media&token=156e449b-c739-46b1-b85b-7d142e0562ef',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: 'rgba(0,0,0,1)',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#558B2F',
          },
          section01: {
            color: '#000000',
            chips: '#558B2F',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#000000',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'stadium08':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fstadium%2Fstadium08.jpg?alt=media&token=5dcfc32d-e326-4137-b4dc-6b0d1a14de05',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: 'rgba(0,0,0,1)',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#78909C',
          },
          section01: {
            color: '#000000',
            chips: '#78909C',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#000000',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'stadium09':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fstadium%2Fstadium09.jpg?alt=media&token=8e71bba4-4691-4e86-ba96-401c571ce648',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: 'rgba(0,0,0,1)',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#78909C',
          },
          section01: {
            color: '#000000',
            chips: '#78909C',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#000000',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'stadium10':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fstadium%2Fstadium10.jpg?alt=media&token=ffa24c92-a262-4114-9782-6651b383bff0',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: 'rgba(0,0,0,1)',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#00C853',
          },
          section01: {
            color: '#000000',
            chips: '#00C853',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#000000',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'stadium11':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fstadium%2Fstadium11.jpg?alt=media&token=e956f452-769a-4e4d-b0b7-bbb0978c0a76',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: 'rgba(0,0,0,1)',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#78909C',
          },
          section01: {
            color: '#000000',
            chips: '#78909C',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#000000',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'stadium12':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fstadium%2Fstadium12.jpg?alt=media&token=2ea3ce97-cc9e-45ff-8ac5-8611fbd3c6cc',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: 'rgba(0,0,0,1)',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#78909C',
          },
          section01: {
            color: '#000000',
            chips: '#78909C',
            text: '#fafafa',
            title: '#fafafa',
            shadow: '',
          },
          section02: {
            color: '#fafafa',
            text: '#000000',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break

    case 'schwarz':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fcolors%2Fblack01.jpg?alt=media&token=8e36b759-cb05-48b3-8749-4f78cf5259e4',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          sponsor: '#263238',
          footer: '#000',
          mobile_nav: 'rgba(0,0,0,1)',
          start: {
            title: '#ffffff',
            text: '#ffffff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#616161',
          },
          section01: {
            color: '#000000',
            chips: '#616161',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#fff',
            text: '#000000',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(255,255,255,0.7)',
          infosdark: false,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'blau':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fcolors%2Fblue01.jpg?alt=media&token=8c2ea10d-7502-4874-ad63-0e8ef1c7ba8c',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: '#1565C0',
          sponsor: '#42A5F5',
          footer: '#1565C0',
          mobile_nav: '#1565C0',
          start: {
            title: '#ffffff',
            text: '#ffffff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#039BE5',
          },
          section01: {
            color: '#01579B',
            chips: '#039BE5',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#80D8FF',
            text: '#01579B',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(255,255,255,0.7)',
          infosdark: false,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'rot':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fcolors%2Frot01.jpg?alt=media&token=5254d85b-1f23-4a87-8293-14fac1e4c8d8',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: '#B71C1C',
          sponsor: '#EF5350',
          footer: '#B71C1C',
          mobile_nav: '#B71C1C',
          start: {
            title: '#ffffff',
            text: '#ffffff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#E53935',
          },
          section01: {
            color: '#B71C1C',
            chips: '#E53935',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#FF8A80',
            text: '#B71C1C',
            shadow: '',
          },
          shadows:
            '#B71C1C 0px 0px 5px, #B71C1C 0px 0px 10px, #B71C1C 0px 0px 15px, #B71C1C 0px 0px 20px, #B71C1C 0px 0px 30px, #B71C1C 0px 0px 40px, #B71C1C 0px 0px 50px, #B71C1C 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(183,28,28,0.9)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'grau':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fcolors%2Fgrau01.jpg?alt=media&token=99eee30d-452f-414d-9607-7fef23516064',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: '#333',
          sponsor: '#666',
          footer: '#000',
          mobile_nav: '#333',
          start: {
            title: '#ffffff',
            text: '#ffffff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#515151',
          },
          section01: {
            color: '#222222',
            chips: '#515151',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#999',
            text: '#000',
            shadow: '',
          },
          shadows:
            '#333 0px 0px 5px, #333 0px 0px 10px, #333 0px 0px 15px, #333 0px 0px 20px, #333 0px 0px 30px, #333 0px 0px 40px, #333 0px 0px 50px, #333 0px 0px 75px;',
          buttons: '#FFF',
          buttonsdark: false,
          infos: 'rgba(50,50,50,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'weiss':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fcolors%2Fweiss01.jpg?alt=media&token=c576ea61-96f1-43ad-8ee9-f6670100faf4',
          position: 'center center',
        },
        dark: false,
        light: true,
        colors: {
          headings: '#000',
          navigation: '#DDD',
          sponsor: '#666',
          footer: '#000',
          mobile_nav: '#DDD',
          start: {
            title: '#ffffff',
            text: '#ffffff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#ddd',
          },
          section01: {
            color: '#222222',
            chips: '#ddd',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#999',
            text: '#000',
            shadow: '',
          },
          shadows:
            '#EEE 0px 0px 5px, #EEE 0px 0px 10px, #EEE 0px 0px 15px, #EEE 0px 0px 20px, #EEE 0px 0px 30px, #EEE 0px 0px 40px, #EEE 0px 0px 50px, #EEE 0px 0px 75px;',
          buttons: '#000',
          buttonsdark: true,
          infos: 'rgba(210,210,210,0.7)',
          infosdark: false,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'gruen':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fcolors%2Fgruen01.jpg?alt=media&token=90539e93-1884-4327-a60c-c76293b79b3a',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: '#2E7D32',
          sponsor: '#2E7D32',
          footer: '#1B5E20',
          mobile_nav: '#2E7D32',
          start: {
            title: '#ffffff',
            text: '#ffffff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#43A047',
          },
          section01: {
            color: '#1B5E20',
            chips: '#43A047',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#B9F6CA',
            text: '#1B5E20',
            shadow: '',
          },
          shadows:
            '#2E7D32 0px 0px 5px, #2E7D32 0px 0px 10px, #2E7D32 0px 0px 15px, #2E7D32 0px 0px 20px, #2E7D32 0px 0px 30px, #2E7D32 0px 0px 40px, #2E7D32 0px 0px 50px, #2E7D32 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(27,94,32,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'hellgruen':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fcolors%2Fgruen02.jpg?alt=media&token=988d0ad2-0fb3-4c69-ac27-9769287f9529',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: '#558B2F',
          sponsor: '#689F38',
          footer: '#558B2F',
          mobile_nav: '#558B2F',
          start: {
            title: '#ffffff',
            text: '#ffffff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#7CB342',
          },
          section01: {
            color: '#33691E',
            chips: '#7CB342',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#CCFF90',
            text: '#33691E',
            shadow: '',
          },
          shadows:
            '#2E7D32 0px 0px 5px, #2E7D32 0px 0px 10px, #2E7D32 0px 0px 15px, #2E7D32 0px 0px 20px, #2E7D32 0px 0px 30px, #2E7D32 0px 0px 40px, #2E7D32 0px 0px 50px, #2E7D32 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(27,94,32,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'orange':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fcolors%2Forange01.jpg?alt=media&token=14047a93-cb48-4428-b139-8b9816f118fe',
          position: 'center center',
        },
        dark: false,
        light: true,
        colors: {
          headings: '#000',
          navigation: '#FF6F00',
          sponsor: '#FFA000',
          footer: '#FF6F00',
          mobile_nav: '#FF6F00',
          start: {
            title: '#ffffff',
            text: '#ffffff',
            countdown_big: '#fff',
            countdown_light: '#fff',
            chips: '#FB8C00',
          },
          section01: {
            color: '#E65100',
            chips: '#FB8C00',
            text: '#fff',
            title: '#fff',
            shadow: '',
          },
          section02: {
            color: '#FFD180',
            text: '#E65100',
            shadow: '',
          },
          shadows:
            '#c88f1b 0px 0px 5px, #c88f1b 0px 0px 10px, #c88f1b 0px 0px 15px, #c88f1b 0px 0px 20px, #c88f1b 0px 0px 30px, #c88f1b 0px 0px 40px, #c88f1b 0px 0px 50px, #c88f1b 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(211,160,32,0.85)',
          infosdark: false,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
    case 'gelb':
      templateobj = {
        background: {
          src:
            'https://firebasestorage.googleapis.com/v0/b/iturnier-de-1584926803622.appspot.com/o/backgrounds%2Fcolors%2Fgelb01.jpg?alt=media&token=a78c5f13-57d7-4e48-8bba-70b19a90fe7b',
          position: 'center center',
        },
        dark: false,
        light: true,
        colors: {
          headings: '#000',
          navigation: '#FFEB3B',
          sponsor: '#FFEB3B',
          footer: '#FFEE58',
          mobile_nav: '#FFEB3B',
          start: {
            title: '#FDD835',
            text: '#FDD835',
            countdown_big: '#000',
            countdown_light: '#000',
            chips: '#FDD835',
          },
          section01: {
            color: '#F57F17',
            chips: '#FDD835',
            text: '#000',
            title: '#000',
            shadow: '',
          },
          section02: {
            color: '#FFFF8D',
            text: '#F57F17',
            shadow: '',
          },
          shadows:
            '#FFFF00 0px 0px 5px, #FFFF00 0px 0px 10px, #FFFF00 0px 0px 15px, #FFFF00 0px 0px 20px, #FFFF00 0px 0px 30px, #FFFF00 0px 0px 40px, #FFFF00 0px 0px 50px, #FFFF00 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(255,255,0,0.85)',
          infosdark: false,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break

    default:
      templateobj = {
        background: {
          src: '/design/flutlicht/default.jpg',
          position: 'center center',
        },
        dark: true,
        light: false,
        colors: {
          headings: '#fff',
          navigation: 'rgba(0,0,0,0.7)',
          mobile_nav: '#000',
          sponsor: '#263238',
          footer: '#000',
          start: {
            title: '#fafafa',
            text: '#fafafa',
            countdown_big: '#fafafa',
            countdown_light: '#fafafa',
            chips: '#d00550',
          },
          section01: {
            color: '#000000',
            chips: '#d00550',
            text: '#fafafa',
            title: '#d00550',
            shadow: '',
          },
          section02: {
            color: '#b86ab2',
            text: '#ede8f1',
            shadow: '',
          },
          farbe03: {
            color: '#214f7b',
            text: '#fafafa',
            shadow: '',
          },
          shadows:
            '#263238 0px 0px 5px, #263238 0px 0px 10px, #263238 0px 0px 15px, #263238 0px 0px 20px, #263238 0px 0px 30px, #263238 0px 0px 40px, #263238 0px 0px 50px, #263238 0px 0px 75px;',
          buttons: '#ECEFF1',
          buttonsdark: false,
          infos: 'rgba(0,0,0,0.7)',
          infosdark: true,
        },
        fonts: {
          title: "'Roboto', sans-serif",
          subtitle: "'Roboto', sans-serif",
        },
        sites: {
          start: 'centered',
        },
      }
      break
  }
  return templateobj
}
export function getAdminTemplate(template) {
  var templateobj = {
    colors: '',
  }
  templateobj.isDark = (color) => {
    // Variables for red, green, blue values
    var r, g, b, hsp

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If RGB --> store the red, green, blue values in separate variables
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
      )

      r = color[1]
      g = color[2]
      b = color[3]
    } else {
      // If hex --> Convert it to RGB: http://gist.github.com/983661
      color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

      r = color >> 16
      g = (color >> 8) & 255
      b = color & 255
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return false
    } else {
      return true
    }
  }
  switch (template) {
    default:
      templateobj.colors = {
        primary: 'rgba(0,0,0,1)',
        secondary: 'rgba(40,40,40,1)',
      }
      templateobj.transp_colors = {
        t25: {
          primary: 'rgba(0,0,0,0.75)',
          secondary: 'rgba(44,44,44,0.75)',
        },
        t50: {
          primary: 'rgba(0,0,0,0.5)',
          secondary: 'rgba(44,44,44,0.5)',
        },
        t75: {
          primary: 'rgba(0,0,0,0.25)',
          secondary: 'rgba(44,44,44,0.25)',
        },
      }
      templateobj.buttons = {
        submit: '#00cc00',
        abbruch: '#e41d1a',
        action: '#eeeeee',
      }
      templateobj.inputs = {
        text: '#ffffff',
      }
  }
  return templateobj
}

export default { getTemplate, getAdminTemplate }
