import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import { fass } from '@fortawesome/sharp-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

/* add icons to the library */
library.add(fas, far, fal, fad, fat, fass)

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: '#000000',
            },
            light: {
                primary: '#FFFFFF',
            },
        },
    },
});
