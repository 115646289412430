export class Color {
    constructor(
        alpha,
        hex,
        hexa,
        hsla,
        hsva,
        hue,
        rgba,
    ) {
        this.alpha = alpha || 1
        this.hex = hex || ''
        this.hexa = hexa || ''
        this.hsla = hsla || {
            a: 1,
            h: 0,
            l: 0,
            s: 0
        }
        this.hsva = hsva || {
            a: 1,
            h: 0,
            s: 0,
            v: 0
        }
        this.hue = hue || ''
        this.rgba = rgba || {
            a: 1,
            b: 0,
            g: 0,
            r: 0
        }
    }

    isDark(){
        const r = this.rgba.r
        const g = this.rgba.g
        const b = this.rgba.b

        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

        // Using the HSP value, determine whether the color is light or dark
        if (hsp > 127.5) {
            return false
        } else {
            return true
        }
    }

    get_rgba(alpha){
        const color = this.rgba
        return 'rgba('+color.r+', '+color.g+', '+color.b+', '+(alpha ? alpha : color.a)+')'
    }

    toFirestore(){
        return {
            alpha: this.alpha,
            hex: this.hex,
            hexa: this.hexa,
            hsla: this.hsla,
            hsva: this.hsva,
            hue: this.hue,
            rgba: this.rgba
        }
    }
}