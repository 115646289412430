export class Zeitraum_Tag {
    constructor(
        id,
        datum,
        endzeit,
        ref,
        startzeit
    ) {
        this.id = id
        this.datum = datum
        this.endzeit = endzeit
        this.ref = ref
        this.startzeit = startzeit
        this.datumdialog = false
        this.endzeitdialog = false
        this.startzeitdialog = false
    }

    toFirestore() {
        return {
            id: this.id,
            datum: this.datum,
            endzeit: this.endzeit,
            ref: this.ref,
            startzeit: this.startzeit
        }
    }
}