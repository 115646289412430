import Runde from "./runde";

const rundeConverter = {
    toFirestore: (runde) => {
        return runde.toFirestore()
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Runde(
            snapshot.id,
            data.name,
            data.modus,
            data.anz_teams,
            data.beginn,
            data.gruppen,
            data.pause,
            data.pausen,
            data.spiele,
            data.spielfelder,
            data.spielzeit,
            data.teams,
            data.verteilung,
            data.abhaengigkeit,
            data.platzierungsregeln
        )
    }
}

export default rundeConverter