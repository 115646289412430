import md5 from "md5"

export class Trainer {
    constructor(id, vorname, nachname, mobil, whatsapp, email){
        this.id = id || md5(Date.now())
        this.vorname = vorname || ''
        this.nachname = nachname || ''
        this.mobil = mobil
        this.whatsapp = whatsapp || false
        this.email = email || ''
    }

    toFirestore(){
        return {
            id: this.id,
            vorname: this.vorname,
            nachname: this.nachname,
            mobil: this.mobil,
            whatsapp: this.whatsapp,
            email: this.email
        }
    }
}