import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'iTurnier.de',
    component: () => import('@/views/App-Landing-Page/index.vue'),
    children: [
      {
        path: '',
        name: 'iTurnier.de',
        component: () => import('@/views/App-Landing-Page/start.vue')
      },
      {
        path: 'impressum',
        name: 'Impressum - iTurnier.de',
        component: () => import('@/views/App-Landing-Page/impressum.vue')
      },
      {
        path: 'datenschutz',
        name: 'Datenschutz - iTurnier.de',
        component: () => import('@/views/App-Landing-Page/datenschutz.vue')
      }
    ]
  },
  {
    path: '/:alias',
    name: 'Turnierseite',
    component: () => import('@/views/turnierseite/index.vue'),
    children: [
      {
        path: '',
        name: 'Startseite',
        component: () => import('@/views/turnierseite/startseite/index.vue'),
      },
      {
        path: 'spielplan',
        name: 'Spielplan',
        component: () => import('@/views/turnierseite/spielplan/index.vue'),
      },
      {
        path: 'statistiken',
        name: 'Statistiken',
        component: () => import('@/views/turnierseite/statistiken/index.vue'),
      },
      {
        path: '*',
        component: () => import('@/views/error-seiten/404.vue')
      }
    ],
  },
  {
    path: '*',
    component: () => import('@/views/error-seiten/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
