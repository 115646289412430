import { doc, DocumentReference, getFirestore } from "firebase/firestore"
import { Spieler } from "../turnier/spieler"
import { Tormusik } from "./tormusik"

export default class Team {
    constructor(id, name, logo, logo_round, logo_background, tormusik, ref, kader, trikots, custom_fields){
        this.id = id
        this.name = name
        this.logo = logo
        this.logo_round = logo_round || true
        this.logo_background = logo_background || 'transparent'
        this.tormusik = new Tormusik(tormusik?.id, tormusik?.name, ref?.parent?.parent?.id)

        this.ref = ref instanceof DocumentReference ? ref : ''
        this.kader = Array.isArray(kader) ? kader.map(item => {
            return new Spieler(item.id, item.vorname, item.nachname, item.trikotnummer)
        }) : []
        this.kader.sort((a, b) => a.trikotnummer - b.trikotnummer)
        this.trikots = {
            heimfarbe: trikots?.heimfarbe || '',
            ausweichfarbe: trikots?.ausweichfarbe || ''
        }

        this.custom_fields = Object.assign({}, custom_fields)
    }

    toFirestore(){
        return {
            id: this.id,
            name: this.name,
            logo: this.logo,
            logo_round: this.logo_round || '',
            logo_round_background: this.logo_round_background || '',
            tormusik: this.tormusik.toFirestore(),
            kader: this.kader.map(item => {
                return item.toFirestore()
            }),
            trikots: this.trikots,
            custom_fields: Object.assign({}, this.custom_fields)
        }
    }
}