export class Turniermodus_Settings {
    constructor(
        settings
    ) {
        this.countdown = settings?.countdown || false
        this.countdown_sound = settings?.countdown_sound || 'Countdown-6.mp3'
        this.countdown_sound_kuerzen = settings?.countdown_sound_kuerzen || false
        this.dauer_countdown = settings?.dauer_countdown || 5
        this.dauer_sirene = settings?.dauer_sirene || 2
        this.dauer_tormusik = settings?.dauer_tormusik || 12
        this.tormusik = settings?.tormusik || false
        this.netto_spielzeit = settings?.netto_spielzeit || false
        this.letzte_minute_netto = settings?.letzte_minute_netto || false
        this.teamfouls = settings?.teamfouls || false
        this.teamfoul_limit = settings?.teamfoul_limit || 5
        this.sirene = settings?.sirene || false
        this.torschuetzen_erfassen = settings?.torschuetzen_erfassen || false
        this.duration_torschuetze = settings?.duration_torschuetze || 8
        this.screens = settings?.screens || []
        this.keyboard_steuerung = settings?.keyboard_steuerung || false
    }

    toFirestore(){
        return {
            countdown: this.countdown,
            countdown_sound: this.countdown_sound,
            countdown_sound_kuerzen: this.countdown_sound_kuerzen,
            dauer_countdown: this.dauer_countdown,
            dauer_sirene: this.dauer_sirene,
            dauer_tormusik: this.dauer_tormusik,
            tormusik: this.tormusik,
            netto_spielzeit: this.netto_spielzeit,
            letzte_minute_netto: this.letzte_minute_netto,
            teamfouls: this.teamfouls,
            teamfoul_limit: this.teamfoul_limit,
            sirene: this.sirene,
            torschuetzen_erfassen: this.torschuetzen_erfassen,
            duration_torschuetze: this.duration_torschuetze,
            screens: this.screens,
            keyboard_steuerung: this.keyboard_steuerung
        }
    }
}