export class Veranstaltungsort {
    constructor(
        name,
        strasse,
        nr,
        plz,
        ort
    ) {
        this.name = name || ''
        this.strasse = strasse || ''
        this.nr = nr || ''
        this.plz = plz || ''
        this.ort = ort || ''
    }

    toFirestore() {
        return {
            name: this.name,
            strasse: this.strasse,
            nr: this.nr,
            plz: this.plz,
            ort: this.ort
        }
    }
}