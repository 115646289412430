import md5 from "md5"

export class Cafeteria_Artikel {
    constructor(
        id,
        name,
        beschreibung,
        preis,
        kategorie
    ) {
        this.id = id || md5(Date.now())
        this.name = name || ''
        this.beschreibung = beschreibung || ''
        this.preis = parseFloat(preis || 0)
        this.kategorie = kategorie || ''
    }

    toFirestore() {
        return {
            id: this.id,
            name: this.name,
            beschreibung: this.beschreibung,
            preis: this.preis,
            kategorie: this.kategorie
        }
    }
}