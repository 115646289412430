import { DocumentReference } from 'firebase/firestore'
import Gruppe from './spiel/gruppe'
import Mannschaft from './spiel/mannschaft'
import Spiel from './spiel/spiel'
import Spielfeld from './spiel/spielfeld'
import Spielzeit from './spiel/spielzeit'
import Team from './spiel/team'
import Zeit from './zeit'

const md5 = require('md5')

export default class Runde {
  /**
   * @var id
   * Runden-ID
   */
  id = ''

  /**
   * @var name
   * Name der Runde
   */
  name = ''

  /**
   * @var modus
   * Modus dieser Runde
   */
  modus = ''

  /**
   * @var anz_teams
   * Anzahl Teams
   */
  anz_teams = ''

  /**
   * @var tage
   * Tage an denen Spiele stattfinden dürfen
   */
  tage = []

  /**
   * @var teams
   * Teams für die die Spiele generiert werden sollen
   */
  teams = []

  /**
   * @var gruppen
   * Gruppen für die Spiele generiert werden sollen
   */
  gruppen = []

  /**
   * @var ko_runden
   * KO-Runden für die Spiele generiert werden sollen
   */
  ko_runden = []

  /**
   * @var spieltage
   * Generierte Spieltage
   */
  spieltage = []

  /**
   * @var spiele
   * Generierte Spiele
   */
  spiele = []

  /**
   * @var spielzeit
   * Spielzeit in Minuten
   */
  spielzeit = 10

  /**
   * @var pause
   * Pause in Minuten
   */
  pause = 2

  /**
   * @var spielfelder
   * Verfügbare Spielfelder
   */
  spielfelder = [
    {
      id: 1,
      name: 'Spielfeld 1',
      kurz: '1',
      info: '',
    },
  ]

  /**
   * @var verteilung
   * Verteilung der Spiele
   */
  verteilung = 'abwechselnd'

  /**
   * @var beginn
   * Beginn der Runde ( = Erstes Spiel)
   */
  beginn = {
    datum: '',
    uhrzeit: '',
    timestamp: '',
  }

  /**
   * Constructor
   * @param {int} anz_teams
   * @param {boolean} rueckrunde
   * @param {array} tage
   * @param {array} teams
   */
  constructor(
    id,
    name, 
    modus,
    anz_teams,
    beginn,
    gruppen,
    pause,
    pausen,
    spiele,
    spielfelder = [
      {
        id: 1,
        name: 'Spielfeld 1',
        kurz: '1',
        info: '',
      },
    ],
    spielzeit,
    teams,
    verteilung,
    abhaengigkeit,
    platzierungsregeln
  ) {
    this.id = id || md5(name)
    this.anz_teams = anz_teams || 0,
    this.beginn = new Zeit(beginn?.datum, beginn?.uhrzeit, beginn?.timestamp)
    this.gruppen = gruppen?.map(item => {
      const tabelle = this.calculate_tabelle(item.id)
      return new Gruppe(item.id, item.name, item.kurz, item.anz_teams, item.rueckrunde, item.teams, tabelle)
    }) || []
    this.name = name
    this.modus = modus ? (modus == 'KO' ? modus : 'Gruppe') : ' Gruppe'
    this.pause = pause || ''
    this.pausen = pausen || []
    this.teams = teams?.map(item => {
      return new Mannschaft(item.id, item.name, item.tore, item.gruppe, item.team, item.link)
    })
    this.spiele = spiele?.map(item => {
      return new Spiel(
        item.id,
        item.start,
        item.ende,
        item.spieltag,
        item.spielfeld,
        this.teams.find(team => team.id == item.heim),
        this.teams.find(team => team.id == item.gast),
        item.status,
        item.spielzeit,
        item.runde,
        item.gruppe,
        item.tore_heim,
        item.tore_gast,
        item.spiel_gewinner,
        item.spiel_verlierer,
        item.zweiminheim,
        item.zweimingast,
        item.name,
        item.fouls_heim,
        item.fouls_gast,
        item.torschuetzen,
        item.ticker
      )
    })
    this.tabellen_berechnung = [
      {
        name: 'Punkte',
        sort_function: (a, b) => {
          if(a.punkte > b.punkte){
            return -1
          }
          else if(a.punkte < b.punkte){
            return 1
          }
          else {
            return 0
          }
        }
      },
      {
        name: 'Tordifferenz',
        sort_function: (a, b) => {
          const td_a = parseInt(a.tore - a.gegentore)
          const td_b = parseInt(b.tore - b.gegentore)

          if(td_a > td_b){
            return -1
          }
          else if(td_a < td_b){
            return 1
          }
          else {
            return 0
          }
        }
      },
      {
        name: 'Meiste Tore',
        sort_function: (a, b) => {
          if(a.tore > b.tore){
            return -1
          }
          else if(a.tore < b.tore){
            return 1
          }
          else {
            return 0
          }
        }
      },
      {
        name: 'Direkter Vergleich',
        sort_function: (a, b) => {
          const spiele = this.spiele.filter(spiel => (spiel.heim.id == a.id && spiel.gast.id == b.id) || (spiel.heim.id == a.id && spiel.gast.id == b.id))
          var punkte_a = 0
          var punkte_b = 0
          var tore_a = 0
          var gegentore_a = 0
          var tore_b = 0
          var gegentore_b = 0

          spiele.forEach(spiel => {
            if(spiel.status == 'beendet'){
              if(spiel.heim.id == a.id){
                tore_a += parseInt(spiel.tore_heim)
                gegentore_a += parseInt(spiel.tore_gast)
                tore_b += parseInt(spiel.tore_gast)
                gegentore_b += parseInt(spiel.tore_heim)
                if(spiel.tore_heim > spiel.tore_gast){
                  punkte_a += 3
                }
                else if(spiel.tore_heim == spiel.tore_gast){
                  punkte_a += 1
                  punkte_b += 1
                }
                else if(spiel.tore_heim < spiel.tore_gast){
                  punkte_b += 3
                }
              }
              else if(spiel.heim.id == b.id){
                tore_b += parseInt(spiel.tore_heim)
                gegentore_b += parseInt(spiel.tore_gast)
                tore_a += parseInt(spiel.tore_gast)
                gegentore_a += parseInt(spiel.tore_heim)
                if(spiel.tore_heim > spiel.tore_gast){
                  punkte_b += 3
                }
                else if(spiel.tore_heim == spiel.tore_gast){
                  punkte_a += 1
                  punkte_b += 1
                }
                else if(spiel.tore_heim < spiel.tore_gast){
                  punkte_a += 3
                }
              }
              
            }
          })

          if(punkte_a > punkte_b){
            return -1
          }
          else if(punkte_a < punkte_b){
            return 1
          }
          else {
            var td_a = tore_a - gegentore_a 
            var td_b = tore_b - gegentore_b 

            if(td_a > td_b){
              return -1
            }
            else if(td_a < td_b){
              return 1
            }
            else {
              if(tore_a > tore_b){
                return -1
              }
              else if(tore_a < tore_b){
                return 1
              }
              else {
                return 0
              }
            }
          }
        }
      },
      {
        name: 'Meiste Siege',
        sort_function: (a, b) => {
          if(a.siege > b.siege){
            return -1
          }
          else if(a.siege < b.siege){
            return 1
          }
          else {
            return 0
          }
        }
      },
      {
        name: 'Meiste Unentschieden',
        sort_function: (a, b) => {
          if(a.unentschieden > b.unentschieden){
            return -1
          }
          else if(a.unentschieden < b.unentschieden){
            return 1
          }
          else {
            return 0
          }
        }
      },
    ]
    this.platzierungsregeln = this.set_platzierungsregeln(platzierungsregeln) || this.tabellen_berechnung
    this.spielfelder = spielfelder?.map(item => {
      return new Spielfeld(item.id, item.name, item.kurz)
    })
    this.spielzeit = spielzeit
    
    this.verteilung = verteilung

    this.abhaengigkeit = abhaengigkeit || []

    if(this.modus == 'Gruppe'){
      this.gruppen?.forEach(item => {
        item.tabelle = this.calculate_tabelle(item.id)
      })
    }
  }

  calculate_tabelle(gruppe){
    const tabelle = this.teams.filter(team => team.gruppe.id == gruppe)
    
    tabelle.forEach(team => {
      team.punkte = 0
      team.spiele = 0
      team.tore = 0
      team.gegentore = 0
      team.siege = 0
      team.unentschieden = 0
      team.niederlagen = 0

      this.spiele.forEach(spiel => {
        if(spiel.status == 'beendet' || spiel.status == 'gestartet'){
          if(spiel.heim.id == team.id){
            team.spiele++
            team.tore += parseInt(spiel.tore_heim)
            team.gegentore += parseInt(spiel.tore_gast)

            if(spiel.tore_heim > spiel.tore_gast){
              team.punkte += 3
              team.siege++
            }
            else if(spiel.tore_heim == spiel.tore_gast){
              team.punkte += 1
              team.unentschieden++
            }
            else {
              team.niederlagen++
            }
          }
          else if(spiel.gast.id == team.id){
            team.spiele++
            team.tore += parseInt(spiel.tore_gast)
            team.gegentore += parseInt(spiel.tore_heim)

            if(spiel.tore_heim < spiel.tore_gast){
              team.punkte += 3
              team.siege++
            }
            else if(spiel.tore_heim == spiel.tore_gast){
              team.punkte += 1
              team.unentschieden++
            }
            else {
              team.niederlagen++
            }
          }
        }
      })
    })

    tabelle.sort((a, b) => {
      var order = 0
      this.tabellen_berechnung.forEach(regel => {
        if(order == 0){
          order = regel.sort_function(a,b)
        }
      })
      return order
    })

    return tabelle
  }

  set_platzierungsregeln(platzierung){
    if(Array.isArray(platzierung)){
      this.platzierungsregeln = []
      platzierung.forEach(item => {
        const regel = this.tabellen_berechnung.find(t => t.name == item.name)
        if(regel){
          this.platzierungsregeln.push(regel)
        }
      })
    }
  }

  toFirestore(){
    return Object.assign({}, {
      id: this.id,
      name: this.name,
      modus: this.modus,
      anz_teams: this.anz_teams,
      teams: this.teams.map(item => {
        return item.toFirestore()
      }),
      gruppen: this.gruppen.map(item => {
        return item.toFirestore()
      }),
      spiele: this.spiele.map(item => {
        return item.toFirestore()
      }),
      spielzeit: this.spielzeit,
      pause: this.pause,
      spielfelder: this.spielfelder.map(item => {
        return item.toFirestore()
      }),
      verteilung: this.verteilung || '',
      beginn: this.beginn.get_zeit(),
      pausen: this.pausen || [],
      abhaengigkeit: this.abhaengigkeit,
      platzierungsregeln: this.modus == 'Gruppe' ? this.platzierungsregeln.map(item => {
        return { name: item.name }
      }) : []
    })
  }

  async set_gruppen(gruppen) {
    this.gruppen = await gruppen.map(grp => {
      return new Gruppe(grp.id, grp.name, grp.kurz, grp.anz_teams, grp.rueckrunde, grp.teams)
    })
    await this.generate_group_teams()
    this.spiele = []
    this.spieltage = []
    this.gruppen.forEach((grp) => {
      this.generate_spiele(grp)
    })
  }

  set_beginn(datum, uhrzeit) {
    this.beginn.set_from_string(datum, uhrzeit)
  }

  set_spielzeit(spielzeit) {
    this.spielzeit = parseInt(spielzeit)
  }

  set_pause(pause) {
    this.pause = parseInt(pause)
  }

  set_spielfelder(spielfelder) {
    if (Array.isArray(spielfelder)) {
      this.spielfelder = spielfelder.map(item => {
        return new Spielfeld(item.id, item.name, item.kurz)
      })
    }
  }

  async generate_group_teams() {
    this.teams = []
    var n = 1
    return this.gruppen.forEach(async (gruppe) => {
      gruppe.teams = []
      for (var i = 0; i < gruppe.anz_teams; i++) {
        var id = md5(this.id + 'Team ' + n)
        this.teams.push(
          new Mannschaft(
            id,
            'Team ' + n,
            0,
            gruppe
          )
        )
        gruppe.teams.push(id)
        n++
      }
      return
    })
  }

  generate_spiele(group) {
    var teams = this.get_teams(group.teams)

    var spiel = ''

    group.spieltage = []
    group.spiele = []

    var anz_teams = teams.length

    // ungerade Anzahl = "Spielfrei"-Team hinzufügen
    if (anz_teams % 2 != 0) {
      teams.push({
        id: 'Spielfrei',
        name: 'Spielfrei',
        gruppe: {
          id: group.id,
          name: group.name,
        },
      })
      anz_teams = teams.length
    }

    // --- Spielpaarungen bestimmen ---------------------------------------
    var n = anz_teams - 1
    var i = 1
    for (i = 1; i <= anz_teams - 1; i++) {
      var heim = anz_teams
      var gast = i
      // heimspiel? auswärtsspiel?
      if (i % 2 != 0) {
        var temp = gast
        gast = heim
        heim = temp
      }

      this.spieltage.push({
        id: md5('Spieltag ' + i + ' - ' + group.name),
        spieltag: i,
        gruppe: {
          anz_teams: group.anz_teams,
          id: group.id,
          kurz: group.kurz,
          name: group.name,
          rueckrunde: group.rueckrunde,
        },
        spiele: [],
      })
      group.spieltage.push({
        id: md5('Spieltag ' + i + ' - ' + group.name),
        spieltag: i,
        gruppe: {
          anz_teams: group.anz_teams,
          id: group.id,
          kurz: group.kurz,
          name: group.name,
          rueckrunde: group.rueckrunde,
        },
        spiele: [],
      })

      spiel = this.build_spiel(group, teams[heim - 1], teams[gast - 1], i)

      if (
        spiel
          ? spiel.heim.id != 'Spielfrei' && spiel.gast.id != 'Spielfrei'
          : false
      ) {
        this.spieltage
          .find((s) => s.id == md5('Spieltag ' + i + ' - ' + group.name))
          .spiele.push(spiel)

        group.spieltage
          .find((s) => s.id == md5('Spieltag ' + i + ' - ' + group.name))
          .spiele.push(spiel)

        this.spiele.push(spiel)
        group.spiele.push(spiel)
      }

      for (var k = 1; k <= anz_teams / 2 - 1; k++) {
        if (i - k < 0) {
          gast = n + (i - k)
        } else {
          gast = (i - k) % n
          gast = gast == 0 ? n : gast // 0 -> n-1
        }

        heim = (i + k) % n
        heim = heim == 0 ? n : heim // 0 -> n-1

        // heimspiel? auswärtsspiel?
        if (k % 2 == 0) {
          temp = gast
          gast = heim
          heim = temp
        }

        spiel = this.build_spiel(group, teams[heim - 1], teams[gast - 1], i)

        if (
          spiel
            ? spiel.heim.id != 'Spielfrei' && spiel.gast.id != 'Spielfrei'
            : false
        ) {
          this.spieltage
            .find((s) => s.id == md5('Spieltag ' + i + ' - ' + group.name))
            .spiele.push(spiel)

          group.spieltage
            .find((s) => s.id == md5('Spieltag ' + i + ' - ' + group.name))
            .spiele.push(spiel)

          this.spiele.push(spiel)
          group.spiele.push(spiel)
        }
      }
    }

    // Rückrunde ?
    if (group.rueckrunde) {
      this.spieltage.forEach((spieltag) => {
        if (spieltag.gruppe == group) {
          var rueckspiele = []
          spieltag.spiele.forEach((spiel) => {
            var rueckspiel = this.build_spiel(group, spiel.gast, spiel.heim)
            rueckspiele.push(rueckspiel)
          })
          this.spieltage.push({
            id: md5('Spieltag ' + (spieltag.spieltag + i) + ' - ' + group.name),
            spieltag: spieltag.spieltag + i,
            gruppe: spieltag.gruppe,
            spiele: [],
          })
        }
      })
      group.spieltage.forEach((spieltag) => {
        var rueckspiele = []
        spieltag.spiele.forEach((spiel) => {
          var rueckspiel = this.build_spiel(group, spiel.gast, spiel.heim)
          rueckspiele.push(rueckspiel)
        })
        group.spieltage.push({
          id: md5('Spieltag ' + (spieltag.spieltag + i) + ' - ' + group.name),
          spieltag: spieltag.spieltag + i,
          gruppe: spieltag.gruppe,
          spiele: rueckspiele,
        })
      })
      this.spiele.forEach((spiel) => {
        if (spiel.gruppe?.id == group.id) {
          var rueckspiel = this.build_spiel(group, spiel.gast, spiel.heim)
          this.spiele.push(rueckspiel)
        }
      })
    }

    return this.spiele
  }

  get_teams(ids) {
    var teams = []
    ids.forEach((id) => {
      teams.push(this.teams.find((team) => team.id == id))
    })
    return teams
  }

  build_spiel(gruppe, heim, gast, spieltag = '') {
    var spiel_nr = this.spiele.length + 1

    return new Spiel(
      md5('(Spiel)' + this.name + spiel_nr),
      null,
      null,
      spieltag,
      null,
      heim,
      gast,
      'geplant',
      null,
      {
        id: this.id,
        name: this.name
      },
      {
        anz_teams: gruppe.anz_teams,
        id: gruppe.id,
        kurz: gruppe.kurz,
        name: gruppe.name,
        rueckrunde: gruppe.rueckrunde,
      }
    )
  }

  async generate_gruppenspielplan(verteilung, datum, uhrzeit) {
    var beginn = new Zeit(datum, uhrzeit)
    this.set_beginn(datum, uhrzeit)
    var gruppen = []
    var spielplan = []
    var spiele = []
    var spieltage = []
    var spiel_nr = 1
    var spielfeld = 0
    var aktuelles_spiel = new Zeit(null, null, beginn.get_timestamp())

    this.verteilung = verteilung

    if (!verteilung || verteilung == 'abwechselnd') {
      //Spielverteilung: A - B - A - B - ...

      for await (const [index, gruppe] of this.gruppen.entries()) {
        var nr = spiel_nr + index
        for await (const spiel of gruppe.spiele) {
          spielplan.push({ nr: nr, spiel: spiel })
          nr = nr + this.gruppen.length
        }
      }
      spielplan.sort((a, b) => a.nr - b.nr)
      spielplan.forEach((spiel) => {
        console.log(
          `Spiel ${spiel.nr} - ${spiel.spiel.heim.name} vs ${spiel.spiel.gast.name}`,
        )
      })

      for await (const [index, spiel] of spielplan.entries()) {
        const spiel_beginn = new Zeit(
          null,
          null,
          aktuelles_spiel.get_timestamp(),
        )
        spiele.push(
          this.set_spiel_spielzeit(spiel.spiel, spiel_beginn, spielfeld),
        )
        spielfeld++
        if (spielfeld == this.spielfelder.length) {
          aktuelles_spiel.set_time(0, this.spielzeit + this.pause)
          spielfeld = 0
        }
      }

      // this.gruppen = gruppen
      this.spiele = spiele
      this.spiele.sort(
        (a, b) =>
          a.start?.timestamp - b.start?.timestamp ||
          a.spielfeld.id - b.spielfeld.id,
      )
    }
    if (verteilung == 'spieltage') {
      //Spielverteilung: Spieltag 1 A - Spieltag 1 B - Spieltag 2 A - Spieltag 2 B - ...
      //if (this.spielfelder.length == 1) {
      this.spieltage.sort(
        (a, b) =>
          a.spieltag - b.spieltag || a.gruppe.kurz.localeCompare(b.gruppe.kurz),
      )

      this.spiele.sort(
        (a, b) =>
          a.spieltag - b.spieltag || a.gruppe.kurz.localeCompare(b.gruppe.kurz),
      )

      for await (const [index, spiel] of this.spiele.entries()) {
        console.log(
          `Spiel ${index + 1} - ${spiel.heim.name} vs ${spiel.gast.name}`,
        )
        const spiel_beginn = new Zeit(
          null,
          null,
          aktuelles_spiel.get_timestamp(),
        )
        spiele.push(this.set_spiel_spielzeit(spiel, spiel_beginn, spielfeld))
        spielfeld++
        if (spielfeld == this.spielfelder.length) {
          aktuelles_spiel.set_time(0, this.spielzeit + this.pause)
          spielfeld = 0
        }
      }

      this.spiele.sort((a, b) => a.start?.timestamp - b.start?.timestamp)
      //}
    }
    if (verteilung == 'gruppen') {
      //Spielverteilung: Alle Spiele A - Alle Spiele B

      this.spiele.sort(
        (a, b) =>
          a.gruppe.kurz.localeCompare(b.gruppe.kurz) || a.spieltag - b.spieltag,
      )

      for await (const [index, spiel] of this.spiele.entries()) {
        console.log(
          `Spiel ${index + 1} - ${spiel.heim.name} vs ${spiel.gast.name}`,
        )
        const spiel_beginn = new Zeit(
          null,
          null,
          aktuelles_spiel.get_timestamp(),
        )
        spiele.push(this.set_spiel_spielzeit(spiel, spiel_beginn, spielfeld))
        spielfeld++
        if (
          (this.spielfelder.length > parseInt(spiel.gruppe.anz_teams / 2) &&
            spielfeld == parseInt(spiel.gruppe.anz_teams / 2)) ||
          spielfeld == this.spielfelder.length
        ) {
          aktuelles_spiel.set_time(0, this.spielzeit + this.pause)
          spielfeld = 0
        }
      }

      this.spiele.sort((a, b) => a.start?.timestamp - b.start?.timestamp)
    }
  }

  async generate_ko_spielplan(datum, uhrzeit) {
    var beginn = new Zeit(datum, uhrzeit)
    this.set_beginn(datum, uhrzeit)
    var spielplan = this.spiele
    var spiele = []
    var spielfeld = 0
    var aktuelles_spiel = new Zeit(null, null, beginn.get_timestamp())

      for await (const [index, spiel] of spielplan.entries()) {
        const spiel_beginn = new Zeit(
          null,
          null,
          aktuelles_spiel.get_timestamp(),
        )
        spiele.push(
          this.set_spiel_spielzeit(spiel, spiel_beginn, spielfeld),
        )
        spielfeld++
        if (spielfeld == this.spielfelder.length) {
          aktuelles_spiel.set_time(0, this.spielzeit + this.pause)
          spielfeld = 0
        }
      }

      // this.gruppen = gruppen
      this.spiele = spiele
      this.spiele.sort(
        (a, b) =>
          a.start?.timestamp - b.start?.timestamp ||
          a.spielfeld.id - b.spielfeld.id,
      )
    
  }

  set_spiel_spielzeit(spiel, zeit, spielfeld = 0) {
    spiel.start = new Zeit(zeit.get_datum(), zeit.get_uhrzeit(), zeit.get_timestamp())
    zeit.set_time(0, this.spielzeit)
    spiel.ende = new Zeit(zeit.get_datum(), zeit.get_uhrzeit(), zeit.get_timestamp())
    zeit.set_time(0, this.pause)

    spiel.spielzeit = new Spielzeit(
      'geplant',
      {
        minuten: this.spielzeit,
        sekunden: 0,
      },
      {
        minuten: this.spielzeit,
        sekunden: 0,
      }
    )
    spiel.spielfeld = new Spielfeld(
      this.spielfelder[spielfeld].id,
      this.spielfelder[spielfeld].name,
      this.spielfelder[spielfeld].kurz,
    )
    return spiel
  }

  generate_ko_spiele(art, datum, uhrzeit){

  }
}
