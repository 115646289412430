import Zeit from "../zeit";
import Gruppe from "./gruppe";
import Mannschaft from "./mannschaft";
import Spielfeld from "./spielfeld";
import Spielzeit from "./spielzeit";
import EventEmitter from 'events'
import { ZweiMinutenStrafe } from "./zweiminutenstrafe";

class Spiel extends EventEmitter {
    constructor(
        id,
        start = {
            datum : '',
            uhrzeit: '',
            timestamp: ''
        },
        ende = {
            datum : '',
            uhrzeit: '',
            timestamp: ''
        },
        spieltag,
        spielfeld,
        heim,
        gast,
        status = 'geplant',
        spielzeit = {
            status: 'geplant',
            total: {
                minuten: 0,
                sekunden: 0,
            },
            verbleibend: {
                minuten: 0,
                sekunden: 0
            }
        },
        runde = {
            id: '',
            name: ''
        },
        gruppe,
        tore_heim = 0,
        tore_gast = 0,
        spiel_gewinner = '',
        spiel_verlierer = '',
        zweiminheim = [],
        zweimingast = [],
        name = '',
        fouls_heim = 0,
        fouls_gast = 0,
        torschuetzen = [],
        ticker = [] 
    ) {
        super();
        this.id = id;
        this.start = new Zeit(start?.datum, start?.uhrzeit, start?.timestamp)
        this.ende = new Zeit(ende?.datum, ende?.uhrzeit, ende?.timestamp)
        this.spieltag = spieltag
        this.spielfeld = spielfeld
        this.heim = heim
        this.gast = gast
        this.status = status
        this.runde = runde
        this.gruppe = gruppe
        this.spielzeit = new Spielzeit(spielzeit?.status, spielzeit?.total, spielzeit?.verbleibend)
        this.tore_heim = tore_heim
        this.tore_gast = tore_gast
        this.spiel_gewinner = spiel_gewinner
        this.spiel_verlierer = spiel_verlierer
        this.zweiminheim = zweiminheim.map(item => {
            return new ZweiMinutenStrafe(item.id, spielzeit.status, item.timestamp, item.verbleibend)
        })
        this.zweiminheim.forEach(item => {
            item.on('zeitstrafe_beendet', () => {
                this.zweiminheim = this.zweiminheim.filter(zweimin => zweimin.id != item.id)
            })
        })
        this.zweimingast = zweimingast.map(item => {
            return new ZweiMinutenStrafe(item.id, spielzeit.status, item.timestamp, item.verbleibend)
        })
        this.zweimingast.forEach(item => {
            item.on('zeitstrafe_beendet', () => {
                this.zweimingast = this.zweimingast.filter(zweimin => zweimin.id != item.id)
            })
        })
        this.name = name
        this.fouls_heim = fouls_heim
        this.fouls_gast = fouls_gast
        this.torschuetzen = torschuetzen
        this.ticker = ticker
    }

    tor_heim(){
        if(this.status != 'geplant'){
            this.tore_heim++
        }
    }

    tor_gast(){
        if(this.status != 'geplant'){
            this.tore_gast++
        }
    }

    korrektur_tor_heim(){
        if(this.status != 'geplant' && this.tore_heim > 0){
            this.tore_heim--
        }
    }
    
    korrektur_tor_gast(){
        if(this.status != 'geplant' && this.tore_gast > 0){
            this.tore_gast--
        }
    }

    teamfoul_heim(){
        if(this.status != 'geplant'){
            this.fouls_heim++
        }
    }

    teamfoul_gast(){
        if(this.status != 'geplant'){
            this.fouls_gast++
        }
    }
    
    korr_teamfoul_heim(){
        if(this.status != 'geplant' && this.fouls_heim > 0){
            this.fouls_heim--
        }
    }

    korr_teamfoul_gast(){
        if(this.status != 'geplant' && this.fouls_gast > 0){
            this.fouls_gast--
        }
    }

    add_zwei_minuten_heim() {
        let zweimin = new ZweiMinutenStrafe(undefined, this.spielzeit.status)
        zweimin.on('zeitstrafe_beendet', () => {
            this.zweiminheim = this.zweiminheim.filter(item => item.id != zweimin.id)
        })
        this.zweiminheim.push(zweimin)
    }
    
    remove_zwei_minuten_heim(id) {
        this.zweiminheim = this.zweiminheim.filter(item => item.id != id)
    }
    
    add_zwei_minuten_gast() {
        let zweimin = new ZweiMinutenStrafe(undefined, this.spielzeit.status)
        zweimin.on('zeitstrafe_beendet', () => {
            this.zweimingast = this.zweiminheim.filter(item => item.id != zweimin.id)
        })
        this.zweimingast.push(zweimin)
    }

    remove_zwei_minuten_gast(id) {
        this.zweimingast = this.zweimingast.filter(item => item.id != id)
    }

    spiel_starten(){
        this.status = 'gestartet'
        this.spielzeit.zeit_starten()
        this.zweiminheim.forEach(item => {
            item.zeit_starten()
        })
        this.zweimingast.forEach(item => {
            item.zeit_starten()
        })
    }

    spiel_pausieren(){
        this.spielzeit.zeit_stoppen()
        this.zweiminheim.forEach(item => {
            item.zeit_stoppen()
        })
        this.zweimingast.forEach(item => {
            item.zeit_stoppen()
        })
    }

    spiel_beenden(){
        this.spielzeit.zeit_beenden()
        this.zweiminheim = []
        this.zweimingast = []
    }

    spiel_abschliessen(){
        this.status = 'beendet'
        this.spielzeit.status = 'beendet'
        this.spielzeit.verbleibend.minuten = 0
        this.spielzeit.verbleibend.sekunden = 0
        if(this.tore_heim > this.tore_gast){
            this.spiel_gewinner = this.heim.id
        }
        if(this.tore_heim < this.tore_gast){
            this.spiel_gewinner = this.gast.id
        }
    }

    torschuetzen_erfassen(team, spieler){
        const zeit = this.spielzeit.get_spielminute()
        const spielstand = this.tore_heim+':'+this.tore_gast
        const titel = 'Tor für '+team.name
        let beschreibung = 'In der '+zeit.spielminute+'. Spielminute erzeilt '+team.name+' das Tor zum '+spielstand
        if(spieler && spieler?.id != 'Eigentor'){
            beschreibung = 'In der '+zeit.spielminute+'. Spielminute erzielt '+spieler.vorname+' '+spieler.nachname+' ('+team.name+') das Tor zum '+spielstand
        }
        else if(spieler){
            beschreibung = 'In der '+zeit.spielminute+'. Spielminute fällt ein Eigentor zum '+spielstand
        }
        const daten = {
            type: 'Tor',
            titel: titel,
            beschreibung: beschreibung,
            spielstand: spielstand,
            zeitpunkt: zeit,
            team: {
                id: team.id,
                name: team.name
            },
            spieler: {
                id: spieler?.id || '',
                trikotnummer: spieler?.trikotnummer || '',
                vorname: spieler?.vorname || '',
                nachname: spieler?.nachname || '',
            }
        }
        if(spieler && spieler?.id != 'Eigentor'){
            this.torschuetzen.push({ team: team.id, spieler: spieler.id })
        }
        this.ticker.push(daten)
        return daten
    }

    ereignis_erfassen(type, titel, beschreibung, team, spieler){
        const daten = {
            type: type,
            titel: titel,
            beschreibung: beschreibung,
            zeitpunkt: this.spielzeit.get_spielminute(),
            team: {
                id: team?.id || '',
                name: team?.name || ''
            },
            spieler: {
                id: spieler?.id || '',
                trikotnummer: spieler?.trikotnummer || '',
                vorname: spieler?.vorname || '',
                nachname: spieler?.nachname || '',
            }
        }
        this.ticker.push(daten)
    }

    get_trikotfarbe_heim(){
        if(this.heim.team){
            if(this.heim.team.trikots?.heimfarbe){
                return this.heim.team.trikots.heimfarbe
            }
            else if(this.heim.team.trikots?.ausweichfarbe){
                return this.heim.team.trikots.ausweichfarbe
            }
            else {
                return {
                    name: 'Leibchen',
                    color: 'deep-orange',
                    nicht_kombinierbar: []
                }
            }     
        }
        return ''
    }
    
    get_trikotfarbe_gast(){
        if(this.gast.team){
            if(this.get_trikotfarbe_heim()?.name == this.gast.team?.trikots.heimfarbe?.name || this.get_trikotfarbe_heim()?.nicht_kombinierbar.includes(this.gast.team?.trikots.heimfarbe?.name)){
                if(this.gast.team.trikots.ausweichfarbe){
                    return this.gast.team.trikots.ausweichfarbe
                }
                else {
                    return {
                        name: 'Leibchen',
                        color: 'deep-orange',
                        nicht_kombinierbar: []
                    }
                }
            }
            else {
                return this.gast.team?.trikots?.heimfarbe
            }
        }
        return ''
    }

    toFirestore() {
        return  {
            id: this.id,
            start: this.start.get_zeit(),
            ende: this.ende.get_zeit(),
            spieltag: this.spieltag,
            spielfeld: this.spielfeld instanceof Spielfeld ? this.spielfeld.id : this.spielfeld,
            heim: this.heim instanceof Mannschaft ? this.heim.id : this.heim,
            gast: this.gast instanceof Mannschaft ? this.gast.id : this.gast,
            status: this.status,
            runde: this.runde,
            gruppe: this.gruppe instanceof Gruppe ? this.gruppe.toFirestore() : this.gruppe ? this.gruppe : '',
            spielzeit: this.spielzeit.toFirestore(),
            tore_heim: this.tore_heim,
            tore_gast: this.tore_gast,
            spiel_gewinner: this.spiel_gewinner || '',
            spiel_verlierer: this.spiel_verlierer || '',
            zweiminheim: this.zweiminheim.map(item => {
                return item.toFirestore()
            }),
            zweimingast: this.zweimingast.map(item => {
                return item.toFirestore()
            }),
            name: this.name,
            fouls_heim: this.fouls_heim,
            fouls_gast: this.fouls_gast,
            torschuetzen: this.torschuetzen,
            ticker: this.ticker
        }
    }
}

export default Spiel