import md5 from "md5";

export class Ansprechpartner {
    constructor(
        id,
        name,
        telefon,
        mobil,
        email,
        whatsapp,
        telefon_public = false,
        mobil_public = false,
        email_public = false,
        whatsapp_public = false
    ) {
        this.id = id || md5(Date.now())
        this.name = name || ''
        this.telefon = telefon || ''
        this.mobil = mobil || ''
        this.email = email || ''
        this.whatsapp = whatsapp || ''
        this.telefon_public = telefon_public
        this.mobil_public = mobil_public
        this.email_public = email_public
        this.whatsapp_public = whatsapp_public
    }

    toFirestore(){
        return {
            id: this.id,
            name: this.name,
            telefon: this.telefon,
            mobil: this.mobil,
            email: this.email,
            whatsapp: this.whatsapp,
            telefon_public: this.telefon_public,
            mobil_public: this.mobil_public,
            email_public: this.email_public,
            whatsapp_public: this.whatsapp_public
        }
    }
}