import md5 from "md5";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

export class HeaderImage {
    constructor(
        id,
        name,
        type,
        path,
        customImage
    ) {
        this.name = name || Date.now()
        this.id = id || md5(this.name)
        this.type = type || ''
        this.path = path || ''
        this.customImage = customImage || false
    }

    async upload(turnierid, file){
        const storage = getStorage();

        if(file.type.startsWith('image/') && turnierid){
            this.type = file.type.split('/')[1]
            const imgref = ref(storage, 'Turniere'+'/'+turnierid+'/'+this.name+'.'+this.type)
            return uploadBytes(imgref, file).then(snap => {
                return getDownloadURL(snap.ref).then(url => {
                    this.path = url
                })
            })
        }
        return
    }

    toFirestore(){
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            path: this.path,
            customImage: this.customImage
        }
    }
}

export class TurnierLogo {
    constructor(
        id,
        name,
        type,
        path
    ) {
        this.name = name || 'Turnierlogo'
        this.id = id || md5(this.name)
        this.type = type || ''
        this.path = path || ''
    }

    async upload(turnierid, file){
        const storage = getStorage();

        if(file.type.startsWith('image/') && turnierid){
            this.type = file.type.split('/')[1]
            const imgref = ref(storage, 'Turniere'+'/'+turnierid+'/'+this.name+'.'+this.type)
            return uploadBytes(imgref, file).then(snap => {
                return getDownloadURL(snap.ref).then(url => {
                    this.path = url
                })
            })
        }
        return
    }

    toFirestore(){
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            path: this.path
        }
    }
}