export default class Zeit {
  /**
   * @var {int} year
   * Jahr
   */
  year = 2021

  /**
   * @var {int} month
   * Monat
   * 0 = Januar
   * 1 = Februar
   * 2 = März
   * 3 = April
   * 4 = Mai
   * 5 = Juni
   * 6 = Juli
   * 7 = August
   * 8 = September
   * 9 = Oktober
   * 10 = November
   * 11 = Dezember
   */
  month = 0

  /**
   * @var {int} day
   * Tag im Monat
   */
  day = 1

  /**
   * @var {int} hours
   * Stunden
   */
  hours = 0

  /**
   * @var {int} minutes
   * Minuten
   */
  minutes = 0

  /**
   * @var {int} seconds
   */
  seconds = 0

  /**
   * @var {int} miliseconds
   * Milisekunden
   */
  miliseconds = 0

  /**
   * @var {Date} date
   * Datums-Objekt
   */
  date = ''

  /**
   *
   * @param {string} datum
   * Format YYYY-MM-DD
   *
   * @param {string} uhrzeit
   * Format hh:mm oder hh:mm:ss oder hh:mm:ss:ms
   */
  constructor(datum, uhrzeit, timestamp) {
    if (timestamp) {
      this.date = new Date(timestamp)
    } else {
      this.set_from_string(datum, uhrzeit)
    }
  }

  set_from_string(datum, uhrzeit) {
    if (datum) {
      var date = datum.split('-')
      this.year = parseInt(date[0])
      this.month = parseInt(date[1]) - 1
      this.day = parseInt(date[2])
    }
    if (uhrzeit) {
      var time = uhrzeit.split(':')
      this.hours = parseInt(time[0])
      this.minutes = parseInt(time[1])
      if (time.length > 2) {
        this.seconds = parseInt(time[2])
        if (time.length > 3) {
          this.miliseconds = parseInt(time[3])
        }
      }
    }
    this.date = new Date(
      this.year,
      this.month,
      this.day,
      this.hours,
      this.minutes,
      this.seconds,
      this.miliseconds,
    )
  }

  set_time(hours = 0, minutes = 0, seconds = 0) {
    if (this.date) {
      this.date.setHours(this.date.getHours() + hours)
      this.date.setMinutes(this.date.getMinutes() + minutes)
      this.date.setSeconds(this.date.getSeconds() + seconds)
    }
  }

  get_date() {
    return this.date
  }

  get_zeit() {
    return {
      datum:
        this.date.getFullYear() +
        '-' +
        (this.date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        this.date.getDate().toString().padStart(2, '0'),
      uhrzeit:
        this.date.getHours().toString().padStart(2, '0') +
        ':' +
        this.date.getMinutes().toString().padStart(2, '0') +
        ':' +
        this.date.getSeconds().toString().padStart(2, '0'),
      timestamp: this.date.getTime(),
    }
  }

  get_uhrzeit() {
    return this.date.getHours().toString().padStart(2, '0') +
    ':' +
    this.date.getMinutes().toString().padStart(2, '0') +
    ':' +
    this.date.getSeconds().toString().padStart(2, '0')
  }

  get_datum() {
    return this.date.getFullYear() +
    '-' +
    (this.date.getMonth() + 1).toString().padStart(2, '0') +
    '-' +
    this.date.getDate().toString().padStart(2, '0')
  }
  
  get_datum_format() {
    return this.date.getDate().toString().padStart(2, '0') + '.' + (this.date.getMonth() + 1).toString().padStart(2, '0') + '.' + this.date.getFullYear()
  }

  get_uhrzeit_format() {
    return this.date.getHours().toString().padStart(2, '0') +
    ':' +
    this.date.getMinutes().toString().padStart(2, '0') + ' Uhr'
  }

  get_timestamp() {
    return this.date.getTime()
  }
}
