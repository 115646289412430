import Turnier from '@/classes/turnier/turnier'
import turnierConverter from '@/classes/turnier/turnierConverter'
import { collection, getFirestore, onSnapshot, query, where } from 'firebase/firestore'
import Vue from 'vue'
import Vuex from 'vuex'
import firebase from '@/firebaseConfig'
import router from '@/router'
import style from './template'
import { Theme } from './classes/turnier/website/theme'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        turnier: '',
        template: style.getTemplate('neon01'),
    },
    getters: {
        turnier(state){
            return state.turnier
        },
        theme(state){
            if(state.turnier?.website?.theme){
                return state.turnier.website.theme
            }
            return new Theme()
        },
        template(state) {
            return state.template
        },
    },
    mutations: {
        SET_TURNIER(state, params) {
            if(params.alias) {
                const turnierRef = query(collection(getFirestore(), 'Turniere'), where('alias', '==', params.alias)).withConverter(turnierConverter)
                const turnierSnap = onSnapshot(turnierRef, snap => {
                    if(snap.size > 0){
                        state.turnier = snap.docs[0].data()
                    }
                    else {
                        router.push('/')
                    }
                })
            }
            else {
                router.push('/')
            }
        },
        SET_TEMPLATE(state, template) {
            state.template = style.getTemplate(template)
        },
    },
    actions: {
        openTurnierseite({ commit }, params) {
            commit('SET_TURNIER', params)
        },
        setTemplate({ commit }, params) {
            commit('SET_TEMPLATE', params)
        },
    }
})

export default store