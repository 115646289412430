import { Cafeteria_Artikel } from "./cafeteria-artikel"

export class Cafeteria {
    constructor(artikel = []){
        this.artikel = artikel.map(item => {
            return new Cafeteria_Artikel(item.id, item.name, item.beschreibung, item.preis, item.kategorie)
        })
    }

    toFirestore(){
        return {
            artikel: this.artikel.map(item => {
                return item.toFirestore()
            })
        }
    }
}