import EventEmitter from 'events'

export default class Spielzeit extends EventEmitter {
    constructor(
        status = 'pausiert',
        total,
        verbleibend
    ) {
        super();
        this.active = true;
        this.status = status
        this.total = total
        this.verbleibend = Object.assign({timestamp: 0 }, verbleibend)
        this.timer = {
            minuten: this.verbleibend?.minuten,
            sekunden: this.verbleibend?.sekunden
        }
        this.countdown_tick()
    }

    zeit_starten(){
        this.status = 'gestartet'
        this.verbleibend.timestamp = Date.now()
        this.countdown_tick()
    }

    zeit_stoppen(){
        this.status = 'pausiert'
        this.verbleibend.minuten = parseInt(this.timer.minuten)
        this.verbleibend.sekunden = parseInt(this.timer.sekunden)
    }
    
    zeit_beenden(){
        this.status = 'beendet'
        this.verbleibend.minuten = parseInt(0)
        this.verbleibend.sekunden = parseInt(0)
    }

    get_spielminute(){
        const gesamt = (this.total.minuten * 60) + this.total.sekunden
        const current = (this.timer.minuten * 60) + this.timer.sekunden
        const gespielt = gesamt - current
        return {
            minuten: parseInt(gespielt / 60),
            sekunden: parseInt(gespielt) % 60,
            spielminute: parseInt(gespielt / 60) + 1,
        }
    }

    countdown_tick(){
        if(this.status == 'gestartet') {
            const now = Date.now()
            const spielzeit_seconds = (this.verbleibend.minuten * 60) + this.verbleibend.sekunden 
            const seconds = parseInt((now - this.verbleibend.timestamp) / 1000)

            this.timer.sekunden = parseInt(spielzeit_seconds - seconds) % 60
            this.timer.minuten = parseInt((spielzeit_seconds - seconds) / 60)

            if(this.timer.sekunden <= 0){
                this.timer.sekunden = 0
            }
            if(this.timer.minuten <= 0){
                this.timer.minuten = 0
            }

            // if(this.timer.minuten == 1 && this.timer.sekunden == 0){
            //     const eventname = 'letzte_minute_'+this.verbleibend.timestamp
            //     this.emit(eventname)
            //     console.log(eventname)
            // }
            // if(this.timer.minuten == 0 && this.timer.sekunden == 0){
            //     const eventname = 'spiel_beendet_'+this.verbleibend.timestamp
            //     this.emit(eventname)
            //     console.log(eventname)
            //     this.zeit_stoppen()
            // }

            setTimeout(() => {
                this.countdown_tick()
            }, 1000)
        }
    }

    toFirestore() {
        return {
            status: this.status,
            total: this.total,
            verbleibend: this.verbleibend
        }
    }
}