import Zeit from "../../zeit";
import Gruppe from "../gruppe";
import Mannschaft from "../mannschaft";
import Spielfeld from "../spielfeld";
import Spielzeit from "../spielzeit";
import EventEmitter from 'events'
import { ZweiMinutenStrafe } from "../zweiminutenstrafe";
import Spiel from "../spiel";
import FuninoTeam from "./funinoTeam";
import store from '@/store.js'
import md5 from "md5";

class FuninoSpiel extends Spiel {
    constructor(
        id,
        start = {
            datum : '',
            uhrzeit: '',
            timestamp: ''
        },
        ende = {
            datum : '',
            uhrzeit: '',
            timestamp: ''
        },
        spieltag,
        teams,
        heimteam
    ) {
        super();
        this.id = id || md5(Date.now());
        this.start = new Zeit(start?.datum, start?.uhrzeit, start?.timestamp)
        this.ende = new Zeit(ende?.datum, ende?.uhrzeit, ende?.timestamp)
        this.spieltag = spieltag
        this.teams = Array.isArray(teams) ? teams.map(team => {
            var t = store.state.turnier.teams?.find(item => item.id == team)
            if(t){
                return t
            }
            else {
                var item = store.state.turnierseite.teams?.find(item => item.id == team)
                return item
            }
        }) : []

        if(heimteam){
            var ht = store.state.turnier.teams?.find(item => item.id == heimteam)
            if(ht){
              this.heimteam = ht
            }
            else {
                var item = store.state.turnierseite.teams?.find(item => item.id == heimteam)
                this.heimteam = item
            }
        }

        this.gastteams = this.teams.filter(item => item.id != heimteam)
    }

    tor_heim(){
        if(this.status != 'geplant'){
            this.tore_heim++
        }
    }

    tor_gast(){
        if(this.status != 'geplant'){
            this.tore_gast++
        }
    }

    korrektur_tor_heim(){
        if(this.status != 'geplant' && this.tore_heim > 0){
            this.tore_heim--
        }
    }
    
    korrektur_tor_gast(){
        if(this.status != 'geplant' && this.tore_gast > 0){
            this.tore_gast--
        }
    }

    teamfoul_heim(){
        if(this.status != 'geplant'){
            this.fouls_heim++
        }
    }

    teamfoul_gast(){
        if(this.status != 'geplant'){
            this.fouls_gast++
        }
    }
    
    korr_teamfoul_heim(){
        if(this.status != 'geplant' && this.fouls_heim > 0){
            this.fouls_heim--
        }
    }

    korr_teamfoul_gast(){
        if(this.status != 'geplant' && this.fouls_gast > 0){
            this.fouls_gast--
        }
    }

    add_zwei_minuten_heim() {
        let zweimin = new ZweiMinutenStrafe(undefined, this.spielzeit.status)
        zweimin.on('zeitstrafe_beendet', () => {
            this.zweiminheim = this.zweiminheim.filter(item => item.id != zweimin.id)
        })
        this.zweiminheim.push(zweimin)
    }
    
    remove_zwei_minuten_heim(id) {
        this.zweiminheim = this.zweiminheim.filter(item => item.id != id)
    }
    
    add_zwei_minuten_gast() {
        let zweimin = new ZweiMinutenStrafe(undefined, this.spielzeit.status)
        zweimin.on('zeitstrafe_beendet', () => {
            this.zweimingast = this.zweiminheim.filter(item => item.id != zweimin.id)
        })
        this.zweimingast.push(zweimin)
    }

    remove_zwei_minuten_gast(id) {
        this.zweimingast = this.zweimingast.filter(item => item.id != id)
    }

    spiel_starten(){
        this.status = 'gestartet'
        this.spielzeit.zeit_starten()
        this.zweiminheim.forEach(item => {
            item.zeit_starten()
        })
        this.zweimingast.forEach(item => {
            item.zeit_starten()
        })
    }

    spiel_pausieren(){
        this.spielzeit.zeit_stoppen()
        this.zweiminheim.forEach(item => {
            item.zeit_stoppen()
        })
        this.zweimingast.forEach(item => {
            item.zeit_stoppen()
        })
    }

    spiel_beenden(){
        this.spielzeit.zeit_beenden()
        this.zweiminheim = []
        this.zweimingast = []
    }

    spiel_abschliessen(){
        this.status = 'beendet'
        this.spielzeit.status = 'beendet'
        this.spielzeit.verbleibend.minuten = 0
        this.spielzeit.verbleibend.sekunden = 0
        if(this.tore_heim > this.tore_gast){
            this.spiel_gewinner = this.heim.id
        }
        if(this.tore_heim < this.tore_gast){
            this.spiel_gewinner = this.gast.id
        }
    }

    torschuetzen_erfassen(team, spieler){
        const zeit = this.spielzeit.get_spielminute()
        const spielstand = this.tore_heim+':'+this.tore_gast
        const titel = 'Tor für '+team.name
        let beschreibung = 'In der '+zeit.spielminute+'. Spielminute erzeilt '+team.name+' das Tor zum '+spielstand
        if(spieler && spieler?.id != 'Eigentor'){
            beschreibung = 'In der '+zeit.spielminute+'. Spielminute erzielt '+spieler.vorname+' '+spieler.nachname+' ('+team.name+') das Tor zum '+spielstand
        }
        else if(spieler){
            beschreibung = 'In der '+zeit.spielminute+'. Spielminute fällt ein Eigentor zum '+spielstand
        }
        const daten = {
            type: 'Tor',
            titel: titel,
            beschreibung: beschreibung,
            spielstand: spielstand,
            zeitpunkt: zeit,
            team: {
                id: team.id,
                name: team.name
            },
            spieler: {
                id: spieler?.id || '',
                trikotnummer: spieler?.trikotnummer || '',
                vorname: spieler?.vorname || '',
                nachname: spieler?.nachname || '',
            }
        }
        if(spieler && spieler?.id != 'Eigentor'){
            this.torschuetzen.push({ team: team.id, spieler: spieler.id })
        }
        this.ticker.push(daten)
        return daten
    }

    ereignis_erfassen(type, titel, beschreibung, team, spieler){
        const daten = {
            type: type,
            titel: titel,
            beschreibung: beschreibung,
            zeitpunkt: this.spielzeit.get_spielminute(),
            team: {
                id: team?.id || '',
                name: team?.name || ''
            },
            spieler: {
                id: spieler?.id || '',
                trikotnummer: spieler?.trikotnummer || '',
                vorname: spieler?.vorname || '',
                nachname: spieler?.nachname || '',
            }
        }
        this.ticker.push(daten)
    }

    get_trikotfarbe_heim(){
        if(this.heim.team){
            if(this.heim.team.trikots?.heimfarbe){
                return this.heim.team.trikots.heimfarbe
            }
            else if(this.heim.team.trikots?.ausweichfarbe){
                return this.heim.team.trikots.ausweichfarbe
            }
            else {
                return {
                    name: 'Leibchen',
                    color: 'deep-orange',
                    nicht_kombinierbar: []
                }
            }     
        }
        return ''
    }
    
    get_trikotfarbe_gast(){
        if(this.gast.team){
            if(this.get_trikotfarbe_heim()?.name == this.gast.team?.trikots.heimfarbe?.name || this.get_trikotfarbe_heim()?.nicht_kombinierbar.includes(this.gast.team?.trikots.heimfarbe?.name)){
                if(this.gast.team.trikots.ausweichfarbe){
                    return this.gast.team.trikots.ausweichfarbe
                }
                else {
                    return {
                        name: 'Leibchen',
                        color: 'deep-orange',
                        nicht_kombinierbar: []
                    }
                }
            }
            else {
                return this.gast.team?.trikots?.heimfarbe
            }
        }
        return ''
    }

    toFirestore() {
        return  Object.assign({}, {
            id: this.id,
            start: this.start.get_zeit(),
            ende: this.ende.get_zeit(),
            spieltag: this.spieltag,
            teams: this.teams.map(team => {
                if(team instanceof FuninoTeam){
                    return team.id
                }
                else {
                    return team
                }
            }), 
            heimteam: this.heimteam instanceof FuninoTeam ? this.heimteam.id : this.heimteam
        })
    }
}

export default FuninoSpiel